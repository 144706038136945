<template>
  <v-row>
    <v-container
      class="pt-0"
      :style="{
        maxWidth: !$vuetify.breakpoint.smAndUp ? 'unset !important' : '',
      }"
    >
      <v-row v-show="loadContent" class="fill-height" align-content="center" justify="center" style="height:60vh">
        <v-col class="text-subtitle-1 text-center color-navy-soft" cols="12">
          Tunggu beberapa saat ...
        </v-col>
        <v-col cols="6">
          <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-show="content">
        <v-col cols="12" sm="12">
          <v-row no-gutters>
            <v-btn class="pl-0 pr-2 mt-3" text color="primary" @click="$router.push('/kelas')">
              <v-icon class="mr-2">$prev</v-icon>
              Kelas saya
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!$vuetify.breakpoint.smAndUp"
              rounded
              small
              color="teal"
              text
              class="white px-2"
              @click="showActivity = true"
            >
              <div class="d-flex">
                <v-icon small class="d-block mr-1">ri-lightbulb-line</v-icon>
                Aktivitas
              </div>
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="12" sm="8" class="mb-3">
          <template v-if="isLoading">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <v-col>
                <div class="text-center">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="act.type == 'menonton_video'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3">{{ act.title }}</div>
              <v-col cols="12" class="pa-0">
                <v-card elevation="0">
                  <v-responsive :aspect-ratio="16 / 9">
                    <video-player ref="videoPlayer" :options="videoOptions" @ended="ended" />
                  </v-responsive>
                </v-card>
              </v-col>
              <v-card elevation="0">
                <div class="pa-4">
                  <p class="text-body-2 text-justify mb-4" v-html="act.theory.description"></p>
                </div>
              </v-card>
            </v-row>
          </template>
          <template v-else-if="act.type == 'pre_test'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3 text-capitalize ms-3 text-h6">
                {{ act.title }}
              </div>

              <v-card v-if="act.is_complete === true" class="col-md-12" elevation="0">
                <div v-show="resultNilai" class="pa-4">          
                  <!-- <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <div>{{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>

                        <span class="text-h4 font-weight-bold"
                          >/ 100
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <v-spacer class="py-2"></v-spacer>
                      <div v-if="this.detailClass.results.progress ===100 && this.scorePost >= this.setMinim.minimum_value">
                        
                      </div>
                      <div v-else>      
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirect();"
                          >Ulangi Pre Test</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row> -->
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <!-- <div>{{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div> -->
                      <div v-if="act.settings.opportunity_repeat_test === -1">Tanpa Batas</div>
                      <div v-else>{{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div>
                    </v-col>
                    <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col v-if="act.settings.is_show_score === true" cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>
                        <span class="text-h4 font-weight-bold">/ 100 </span>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        v-if="act.settings.is_show_discussion === true"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPre = true;
                          resultNilai = false;
                        "
                        >Lihat Jawaban</v-btn
                      >
                      <v-spacer class="py-2"></v-spacer>
                      <div v-if="act.settings.opportunity_repeat_test=== 0">
                        
                      </div>
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirect();">
                          <span>Ulangi Pre Test</span>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="showQues" class="pa-4">
                  <!-- <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn icon small class="mr-1" @click="act.current--" :disabled="act.current <= 1">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>
                    <v-btn icon small :disabled="isRequired" @click="act.current++"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon small @click="submitReview(act.settings)">
                          <v-icon>ri-logout-circle-r-line</v-icon>
                        </v-btn>                        
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                    <div v-else>
                      
                    </div>
                  </div> -->
                  <div class="pa-4 d-flex align-center">
                    <p class="text-h6">Nama Peserta : {{ user.fullname }}</p>
                  </div>
                  <v-divider></v-divider> 
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.question.text">
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options">
                        <v-radio
                          v-for="(item, index) in currentQuestion.options"
                          :key="index"
                          class="mb-2"
                        >
                          <template v-slot:label>
                            <div style="display: block;">
                              <div v-html="item.text"></div>
                              <img width="350px" height="auto" class="mt-1 rounded-lg" :src="item.media[0].public_url" v-if="item.media.length > 0">
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <!-- <v-list class="options" :disabled="review">
                        <v-list-item-group
                          :multiple="review"
                          class="snow"
                          v-model="currentQuestion.answer"
                          :mandatory="!!currentQuestion.answer"                          
                          color="primary"
                        >
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in currentQuestion.options"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list> -->
                      <div v-if="review">
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" v-model="correct_answer" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden" v-for="item in 1" :key="item">
                              <v-list-item-content>
                                <div class="mb-4 green--text">Jawaban yang benar adalah {{ "Opsi " + item }}</div>
                                <div class="navy-soft--text">
                                  In feugiat id nec habitant tortor. Faucibus montes.
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider> 
                  <div class="pa-4 navy-soft--text">
                    <div class="row">
                      <div class="col-md-8 align-center d-flex">                        
                        <v-btn color="primary" small class="mr-1" :disabled="act.current <= 1" @click="act.current--">
                          Sebelumnya</v-btn
                        >
                        <div class="mr-1">
                          No
                          <span class="primary--text">{{ act.current }}</span> dari
                          {{ act.settings.number_questions_package }}
                        </div>

                        <v-btn v-if="act.settings.is_skip === false" color="primary" small :disabled="isRequired" @click="act.current++">
                          Selanjutnya
                        </v-btn>
                        <v-btn v-else color="primary" small :disabled="act.current === act.settings.number_questions_package" @click="act.current++">
                          Selanjutnya
                        </v-btn>
                      </div>
                      <div class="col-md-4">                        
                        <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" block small @click="submitReview(act.settings)">
                              <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                              Submit Jawaban
                            </v-btn>
                          </template>
                          <span>Submit</span>
                        </v-tooltip>
                        <div v-else>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="showExplanationPre" class="pa-4">
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn icon small class="mr-1" @click="act.current--" :disabled="act.current <= 1">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>
                    <v-btn icon small :disabled="act.current >= act.total_question" @click="act.current++"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          @click="
                            resultNilai = true;
                            showExplanationPre = false;
                            act.current=1;
                          "
                        >
                          <v-icon>ri-logout-circle-r-line</v-icon>
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.question.text">
                        <!-- {{ currentQuestion.question.text }} -->
                      </div>
                      <!-- <v-list class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow" color="primary">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in currentQuestion.options"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list> -->
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options" disabled>
                        <v-radio
                          v-for="(item, index) in currentQuestion.options"
                          :key="index"
                          class="mb-2"
                        >
                          <template v-slot:label>
                            <div v-html="item.text"></div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
              <v-card v-else class="col-12" elevation="0">
                <div v-show="resultNilaiFalse" class="pa-4">
                 <!--  <v-card v-if="act.opportunity_repeat_test === 0" elevation="0" style="min-height: 60vh; width: 100%" class="col-md-12">
                    <br />
                    <br />
                    <br />
                    <br />
                    <div class="pa-3 text-center">
                      <img src="@/assets/images/success.png" class="text-center mb-2" /><br />
                      <h3 class="font-weight-bold">
                        Anda telah menyelesaikan Pre Test 
                      </h3>
                    </div>
                  </v-card> -->
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <!-- <div>{{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div> -->
                      <div v-if="act.settings.opportunity_repeat_test === -1">Tanpa Batas</div>
                      <div v-else>{{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div>
                    </v-col>
                    <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col v-if="act.settings.is_show_score === true" cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>
                        <span class="text-h4 font-weight-bold">/ 100 </span>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        v-if="act.settings.is_show_discussion === true"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPreFalse = true;
                          resultNilaiFalse = false;
                        "
                        >Lihat Jawaban</v-btn
                      >
                      <v-spacer class="py-2"></v-spacer>
                      <div v-if="act.settings.opportunity_repeat_test===0">
                        
                      </div>
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectFalse();">
                          <span>Ulangi Pre Test</span>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <div>{{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>

                        <span class="text-h4 font-weight-bold"
                          >/ 100
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <v-spacer class="py-2"></v-spacer>
                      <div v-if="act.settings.opportunity_repeat_test===0">
                        
                      </div>
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectFalse();"
                          >Ulangi Pre Test</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row> -->
                </div>
                <div v-show="showQuesFalse" class="pa-4">
                  <!-- <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn icon small class="mr-1" @click="act.current--" :disabled="act.current <= 1">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>

                    <v-btn v-if="act.settings.is_skip === false" icon small :disabled="isRequired" @click="act.current++">
                      <v-icon>$next</v-icon>
                    </v-btn>
                    <v-btn v-else icon small :disabled="act.current === act.settings.number_questions_package" @click="act.current++">
                      <v-icon>$next</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          @click="submitReviewFalse(act.settings)"
                        >
                          <v-icon>ri-logout-circle-r-line</v-icon>
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                    <div v-else>
                      
                    </div>
                  </div>
                  <v-divider></v-divider> -->
                  <div class="pa-4 d-flex align-center">
                    <p class="text-h6">Nama Peserta : {{ user.fullname }}</p>
                  </div>
                  <v-divider></v-divider> 
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.question.text">
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options">
                        <v-radio
                          v-for="(item, index) in currentQuestion.options"
                          :key="index"
                          class="mb-2"
                        >
                          <template v-slot:label>
                            <div style="display: block;">
                              <div v-html="item.text"></div>
                              <img width="350px" height="auto" class="mt-1 rounded-lg" :src="item.media[0].public_url" v-if="item.media.length > 0">
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <!-- <v-list class="options" :disabled="review">
                        <v-list-item-group
                          :multiple="review"
                          class="snow"
                          v-model="currentQuestion.answer"
                          :mandatory="!!currentQuestion.answer"
                          color="primary"
                        >
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in currentQuestion.options"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list> -->
                      <div v-if="review">
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" v-model="correct_answer" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden" v-for="item in 1" :key="item">
                              <v-list-item-content>
                                <div class="mb-4 green--text">Jawaban yang benar adalah {{ "Opsi " + item }}</div>
                                <div class="navy-soft--text">
                                  In feugiat id nec habitant tortor. Faucibus montes.
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider> 
                  <div class="pa-4 navy-soft--text">
                    <div class="row">
                      <div class="col-md-8 align-center d-flex">                        
                        <v-btn color="primary" small class="mr-1" :disabled="act.current <= 1" @click="act.current--">
                          Sebelumnya</v-btn
                        >
                        <div class="mr-1">
                          No
                          <span class="primary--text">{{ act.current }}</span> dari
                          {{ act.settings.number_questions_package }}
                        </div>

                        <v-btn v-if="act.settings.is_skip === false" color="primary" small :disabled="isRequired" @click="act.current++">
                          Selanjutnya
                        </v-btn>
                        <v-btn v-else color="primary" small :disabled="act.current === act.settings.number_questions_package" @click="act.current++">
                          Selanjutnya
                        </v-btn>
                      </div>
                      <div class="col-md-4">                        
                        <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" small block @click="submitReviewFalse(act.settings)">
                              <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                              Submit Jawaban
                            </v-btn>
                          </template>
                          <span>Submit</span>
                        </v-tooltip>
                        <div v-else>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="showExplanationPreFalse" class="pa-4">
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn icon small class="mr-1" @click="act.current--" :disabled="act.current <= 1">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>
                    <v-btn icon small :disabled="act.current >= act.total_question" @click="act.current++"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          @click="
                            resultNilaiFalse = true;
                            showExplanationPreFalse = false;
                            act.current=1;
                          "
                        >
                          <v-icon>ri-logout-circle-r-line</v-icon>
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.question.text">
                        <!-- {{ currentQuestion.question.text }} -->
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options" disabled>
                        <v-radio
                          v-for="(item, index) in currentQuestion.options"
                          :key="index"
                          class="mb-2"
                        >
                          <template v-slot:label>
                            <div v-html="item.text"></div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-row>
          </template>
          <template v-else-if="act.type == 'quiz'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3 text-capitalize ms-3 text-h6">
                {{ act.title }}
              </div>

              <v-card v-if="act.is_complete === true" class="col-md-12" elevation="0">
                <div v-show="resultNilaiQuiz" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <div v-if="act.settings.opportunity_repeat_test === -1">Tanpa Batas</div>
                      <div v-else>{{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div>
                    </v-col>
                    <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col v-if="act.settings.is_show_score === true" cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>

                        <span class="text-h4 font-weight-bold"
                          >/ 100
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        v-if="act.settings.is_show_discussion === true"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationQuiz = true;
                          resultNilaiQuiz = false;
                          getExplan();
                        "
                        >Lihat Pembahasan</v-btn
                      >
                      <v-spacer class="py-2"></v-spacer>
                      <div v-if="act.settings.opportunity_repeat_test - act.repeat_test === 0">
                        
                      </div>
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectQuiz(act.settings);"
                          >Ulangi Kuis</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="showQuesQuiz" class="pa-4">
                  <div class="pa-4 d-flex align-center">
                    <p class="text-h6">Nama Peserta : {{ user.fullname }}</p>
                    <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                      {{ totalSeconds | minutesAndSeconds }}
                    </p>
                  </div>
                  <v-divider></v-divider> 
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.question.text">
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options">
                        <v-radio
                          v-for="(item, index) in currentQuestion.options"
                          :key="index"
                          class="mb-2"
                        >
                          <template v-slot:label>
                            <div style="display: block;">
                              <div v-html="item.text"></div>
                              <img width="350px" height="auto" class="mt-1 rounded-lg" :src="item.media[0].public_url" v-if="item.media.length > 0">
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <div v-if="review">
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" v-model="correct_answer" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden" v-for="item in 1" :key="item">
                              <v-list-item-content>
                                <div class="mb-4 green--text">Jawaban yang benar adalah {{ "Opsi " + item }}</div>
                                <div class="navy-soft--text">
                                  In feugiat id nec habitant tortor. Faucibus montes.
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>

                  <div class="pa-4 navy-soft--text">
                    <div class="row">
                      <div class="col-md-8 align-center d-flex">                        
                        <v-btn color="primary" small class="mr-1" :disabled="act.current <= 1" @click="act.current--">
                          Sebelumnya</v-btn
                        >
                        <div class="mr-1">
                          No
                          <span class="primary--text">{{ act.current }}</span> dari
                          {{ act.settings.number_questions_package }}
                        </div>

                        <v-btn v-if="act.settings.is_skip === false" color="primary" small :disabled="isRequired" @click="act.current++">
                          Selanjutnya
                        </v-btn>
                        <v-btn v-else color="primary" small :disabled="act.current === act.settings.number_questions_package" @click="act.current++">
                          Selanjutnya
                        </v-btn>
                      </div>
                      <div class="col-md-4">                        
                        <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" block small @click="submitQuiz(act.settings);timer_stop();">
                              <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                              Submit Jawaban
                            </v-btn>
                          </template>
                          <span>Submit</span>
                        </v-tooltip>
                        <div v-else>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="showExplanationQuiz" class="pa-4">
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn icon small class="mr-1" @click="act.current--" :disabled="act.current <= 1">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>
                    <v-btn icon small :disabled="act.current >= act.total_question" @click="act.current++"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          @click="
                            resultNilaiQuiz = true;
                            showExplanationQuiz = false;
                            act.current=1;
                          "
                        >
                          <v-icon>ri-logout-circle-r-line</v-icon>
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                        <!-- {{ currentQuestion.question.text }} -->
                      </div>
                      <!-- <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options" disabled>
                        <v-radio
                          v-for="(item, index) in optionQuestionExplan"
                          :key="index"
                          class="mb-2"
                        >
                          <template v-slot:label>
                            <div v-html="item.text"></div>
                          </template>
                        </v-radio>
                      </v-radio-group> -->
                      <v-list class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text">
                              <!-- {{ item.text }}  -->
                            </v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="showStartQuiz" class="pa-4">
                  <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
                    <div class="font-weight-bold mb-3"></div>
                    <v-card elevation="0" style="min-height: 60vh; width: 100%">
                      <br />
                      <br />
                      <br />
                      <br />
                      <div class="pa-3 text-center">
                        <p class="mb-0">
                          Silahkan Anda menekan tombol "Mulai Mengerjakan" untuk memulai mengerjakan {{act.title}}
                        </p>
                        <v-icon class="d-block mb-2" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                        <!-- <img src="@/assets/images/input-file.png" class="text-center mb-2" /><br /> -->
                        <v-btn
                          color="primary"
                          rounded
                          class="mx-auto text-center"
                          @click="showQuesQuiz = true;showStartQuiz = false;timer_start_quiz(act.settings.timer);takePhoto();"
                        >
                          Mulai Mengerjakan
                        </v-btn>
                      </div>
                    </v-card>
                  </v-row>
                </div>
              </v-card>
              <v-card v-else class="col-12" elevation="0">
                <div v-show="resultNilaiQuizFalse" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <div v-if="act.settings.opportunity_repeat_test === -1">Tanpa Batas</div>
                      <div v-else>{{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div>
                    </v-col>
                    <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col v-if="act.settings.is_show_score === true" cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>
                        <span class="text-h4 font-weight-bold">/ 100 </span>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        v-if="act.settings.is_show_discussion === true"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationQuizFalse = true;
                          resultNilaiQuizFalse = false;
                          getExplan();
                        "
                        >Lihat Pembahasan</v-btn
                      >
                      <v-spacer class="py-2"></v-spacer>
                      <div v-if="act.settings.opportunity_repeat_test - act.repeat_test === 0">
                        
                      </div>
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectQuizFalse(act.settings);">
                          <span>Ulangi Kuis</span>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>  
                </div>
                <div v-show="showQuesQuizFalse" class="pa-4">
                  <div class="pa-4 d-flex align-center">
                    <p class="text-h6">Nama Peserta : {{ user.fullname }}</p>
                    <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                      {{ totalSeconds | minutesAndSeconds }}
                    </p>
                  </div>
                  <v-divider></v-divider> 
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.question.text">
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options">
                        <v-radio
                          v-for="(item, index) in currentQuestion.options"
                          :key="index"
                          class="mb-2"
                        >
                          <template v-slot:label>
                            <div style="display: block;">
                              <div v-html="item.text"></div>
                              <img width="350px" height="auto" class="mt-1 rounded-lg" :src="item.media[0].public_url" v-if="item.media.length > 0">
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <div v-if="review">
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" v-model="correct_answer" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden" v-for="item in 1" :key="item">
                              <v-list-item-content>
                                <div class="mb-4 green--text">Jawaban yang benar adalah {{ "Opsi " + item }}</div>
                                <div class="navy-soft--text">
                                  In feugiat id nec habitant tortor. Faucibus montes.
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>

                  <div class="pa-4 navy-soft--text">
                    <div class="row">
                      <div class="col-md-8 align-center d-flex">
                        <v-btn color="primary" small class="mr-1" :disabled="act.current <= 1" @click="act.current--">
                          Sebelumnya</v-btn
                        >
                        <div class="mr-1">
                          No
                          <span class="primary--text">{{ act.current }}</span> dari
                          {{ act.settings.number_questions_package }}
                        </div>

                        <v-btn v-if="act.settings.is_skip === false" color="primary" small :disabled="isRequired" @click="act.current++">
                          Selanjutnya
                        </v-btn>
                        <v-btn v-else color="primary" small :disabled="act.current === act.settings.number_questions_package" @click="act.current++">
                          Selanjutnya
                        </v-btn>
                      </div>
                      <div class="col-md-4">                        
                        <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" small block @click="submitQuizFalse(act.settings);timer_stop();">
                              <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                              Submit Jawaban
                            </v-btn>
                          </template>
                          <span>Submit</span>
                        </v-tooltip>
                        <div v-else>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="showExplanationQuizFalse" class="pa-4">
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn icon small class="mr-1" @click="act.current--" :disabled="act.current <= 1">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>
                    <v-btn icon small :disabled="act.current >= act.total_question" @click="act.current++"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          @click="
                            resultNilaiQuizFalse = true;
                            showExplanationQuizFalse = false;
                            act.current=1;
                          "
                        >
                          <v-icon>ri-logout-circle-r-line</v-icon>
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                        <!-- {{ currentQuestion.question.text }} -->
                      </div>
                      <v-list class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text">
                              <!-- {{ item.text }}  -->
                            </v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="showStartQuizFalse" class="pa-4">
                  <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
                    <div class="font-weight-bold mb-3"></div>
                    <v-card elevation="0" style="min-height: 60vh; width: 100%">
                      <br />
                      <br />
                      <br />
                      <br />
                      <div class="pa-3 text-center">
                        <p class="mb-0">
                          Silahkan Anda menekan tombol "Mulai Mengerjakan" untuk memulai mengerjakan {{act.title}}
                        </p>
                        <v-icon class="d-block mb-2" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                        <!-- <img src="@/assets/images/input-file.png" class="text-center mb-2" /><br /> -->
                        <v-btn
                          color="primary"
                          rounded
                          class="mx-auto text-center"
                          @click="showQuesQuizFalse = true;showStartQuizFalse = false;timer_start_quiz(act.settings.timer);takePhoto();"
                        >
                          Mulai Mengerjakan
                        </v-btn>
                      </div>
                    </v-card>
                  </v-row>
                </div>
              </v-card>
            </v-row>
          </template>
          <template v-else-if="act.type == 'post_test'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3 text-capitalize ms-3 text-h6">
                {{ act.title }}
              </div>

              <v-card v-if="act.is_complete === true" class="col-md-12" elevation="0">
                <div v-show="resultNilaiPost" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <!-- <div>{{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div> -->
                      <div v-if="act.settings.opportunity_repeat_test === -1">Tanpa Batas</div>
                      <div v-else>{{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div>
                    </v-col>
                    <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col v-if="act.settings.is_show_score === true" cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>

                        <span class="text-h4 font-weight-bold"
                          >/ 100
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        v-if="act.settings.is_show_discussion === true"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPost = true;
                          resultNilaiPost = false;
                          getExplan();
                        "
                        >Lihat Jawaban</v-btn
                      >
                      <v-spacer class="py-2"></v-spacer>
                      <div v-if="act.settings.opportunity_repeat_test - act.repeat_test === 0">
                        
                      </div>
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectPost(act.settings)"
                          >Ulangi Post Test</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="showQuesPost" class="pa-4">
                  <div class="pa-4 d-flex align-center">
                    <p class="text-h6">Nama Peserta : {{ user.fullname }}</p>
                    <v-spacer></v-spacer>                    
                    <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                      {{ totalSeconds | minutesAndSeconds }}
                    </p>
                    <p v-else></p>
                  </div>
                  <v-divider></v-divider> 
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.question.text">
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options">
                        <v-radio
                          v-for="(item, index) in currentQuestion.options"
                          :key="index"
                          class="mb-2"
                        >
                          <template v-slot:label>
                            <div style="display: block;">
                              <div v-html="item.text"></div>
                              <img width="350px" height="auto" class="mt-1 rounded-lg" :src="item.media[0].public_url" v-if="item.media.length > 0">
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <div v-if="review">
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" v-model="correct_answer" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden" v-for="item in 1" :key="item">
                              <v-list-item-content>
                                <div class="mb-4 green--text">Jawaban yang benar adalah {{ "Opsi " + item }}</div>
                                <div class="navy-soft--text">
                                  In feugiat id nec habitant tortor. Faucibus montes.
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider> 
                  <div class="pa-4 navy-soft--text">
                    <div class="row">
                      <div class="col-md-8 align-center d-flex">                        
                        <v-btn color="primary" small class="mr-1" :disabled="act.current <= 1" @click="act.current--">
                          Sebelumnya</v-btn
                        >
                        <div class="mr-1">
                          No
                          <span class="primary--text">{{ act.current }}</span> dari
                          {{ act.settings.number_questions_package }}
                        </div>

                        <v-btn v-if="act.settings.is_skip === false" color="primary" small :disabled="isRequired" @click="act.current++">
                          Selanjutnya
                        </v-btn>
                        <v-btn v-else color="primary" small :disabled="act.current === act.settings.number_questions_package" @click="act.current++">
                          Selanjutnya
                        </v-btn>
                      </div>
                      <div class="col-md-4">                        
                        <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" block small @click="submitPost(act.settings);timer_stop();">
                              <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                              Submit Jawaban
                            </v-btn>
                          </template>
                          <span>Submit</span>
                        </v-tooltip>
                        <div v-else>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="showExplanationPost" class="pa-4">
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn icon small class="mr-1" @click="act.current--" :disabled="act.current <= 1">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>
                    <v-btn icon small :disabled="act.current >= act.total_question" @click="act.current++"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          @click="
                            resultNilaiPost = true;
                            showExplanationPost = false;
                            act.current=1;
                          "
                        >
                          <v-icon>ri-logout-circle-r-line</v-icon>
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                        <!-- {{ currentQuestion.question.text }} -->
                      </div>
                      <v-list class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text">
                              <!-- {{ item.text }}  -->
                            </v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="showStartPost" class="pa-4">
                  <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
                    <div class="font-weight-bold mb-3"></div>
                    <v-card elevation="0" style="min-height: 60vh; width: 100%">
                      <br />
                      <br />
                      <br />
                      <br />
                      <div class="pa-3 text-center">
                        <p class="mb-0">
                          Silahkan Anda menekan tombol "Mulai Mengerjakan" untuk memulai mengerjakan {{act.title}}
                        </p>
                        <v-icon class="d-block mb-2" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                        <!-- <img src="@/assets/images/input-file.png" class="text-center mb-2" /><br /> -->
                        <v-btn
                          color="primary"
                          rounded
                          class="mx-auto text-center"
                          @click="showQuesPost = true;showStartPost = false;timer_start(act.type, act.settings);takePhoto();"
                        >
                          Mulai Mengerjakan
                        </v-btn>
                      </div>
                    </v-card>
                  </v-row>
                </div>
              </v-card>
              <v-card v-else class="col-12" elevation="0">
                <div v-show="resultNilaiPostFalse" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <!-- <div>{{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div> -->
                      <div v-if="act.settings.opportunity_repeat_test === -1">Tanpa Batas</div>
                      <div v-else>{{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div>
                    </v-col>
                    <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col v-if="act.settings.is_show_score === true" cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>

                        <span class="text-h4 font-weight-bold"
                          >/ 100
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        v-if="act.settings.is_show_discussion === true"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPostFalse = true;
                          resultNilaiPostFalse = false;
                          show
                          getExplan();
                        "
                        >Lihat Jawaban</v-btn
                      >
                      <v-spacer class="py-2"></v-spacer>
                      <div v-if="act.settings.opportunity_repeat_test - act.repeat_test === 0">
                        
                      </div>
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectPostFalse(act.settings)"
                          >Ulangi Post Test</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="showQuesPostFalse" class="pa-4">
                  <div class="pa-4 d-flex align-center">
                    <p class="text-h6">Nama Peserta : {{ user.fullname }}</p>
                    <v-spacer></v-spacer>                    
                    <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                      {{ totalSeconds | minutesAndSeconds }}
                    </p>
                    <p v-else></p>
                  </div>
                  <v-divider></v-divider> 
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.question.text">
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options">
                        <v-radio
                          v-for="(item, index) in currentQuestion.options"
                          :key="index"
                          class="mb-2"
                        >
                          <template v-slot:label>
                            <div style="display: block;">
                              <div v-html="item.text"></div>
                              <img width="350px" height="auto" class="mt-1 rounded-lg" :src="item.media[0].public_url" v-if="item.media.length > 0">
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <div v-if="review">
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" v-model="correct_answer" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden" v-for="item in 1" :key="item">
                              <v-list-item-content>
                                <div class="mb-4 green--text">Jawaban yang benar adalah {{ "Opsi " + item }}</div>
                                <div class="navy-soft--text">
                                  In feugiat id nec habitant tortor. Faucibus montes.
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider> 
                  <div class="pa-4 navy-soft--text">
                    <div class="row">
                      <div class="col-md-8 align-center d-flex">                        
                        <v-btn color="primary" small class="mr-1" :disabled="act.current <= 1" @click="act.current--">
                          Sebelumnya</v-btn
                        >
                        <div class="mr-1">
                          No
                          <span class="primary--text">{{ act.current }}</span> dari
                          {{ act.settings.number_questions_package }}
                        </div>

                        <v-btn v-if="act.settings.is_skip === false" color="primary" small :disabled="isRequired" @click="act.current++">
                          Selanjutnya
                        </v-btn>
                        <v-btn v-else color="primary" small :disabled="act.current === act.settings.number_questions_package" @click="act.current++">
                          Selanjutnya
                        </v-btn>
                      </div>
                      <div class="col-md-4">                        
                        <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" block small @click="submitPostFalse(act.settings)">
                              <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                              Submit Jawaban
                            </v-btn>
                          </template>
                          <span>Submit</span>
                        </v-tooltip>
                        <div v-else></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="showExplanationPostFalse" class="pa-4">
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn icon small class="mr-1" @click="act.current--" :disabled="act.current <= 1">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>
                    <v-btn icon small :disabled="act.current >= act.total_question" @click="act.current++"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          @click="
                            resultNilaiPostFalse = true;
                            showExplanationPostFalse = false;
                            act.current=1;
                          "
                        >
                          <v-icon>ri-logout-circle-r-line</v-icon>
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                        <!-- {{ currentQuestion.question.text }} -->
                      </div>
                      <v-list class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text">
                              <!-- {{ item.text }}  -->
                            </v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="showStartPostFalse" class="pa-4">
                  <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
                    <div class="font-weight-bold mb-3"></div>
                    <v-card elevation="0" style="min-height: 60vh; width: 100%">
                      <br />
                      <br />
                      <br />
                      <br />
                      <div class="pa-3 text-center">
                        <p class="mb-0">
                          Silahkan Anda menekan tombol "Mulai Mengerjakan" untuk memulai mengerjakan {{act.title}}
                        </p>
                        <v-icon class="d-block mb-2" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                        <!-- <img src="@/assets/images/input-file.png" class="text-center mb-2" /><br /> -->
                        <v-btn
                          color="primary"
                          rounded
                          class="mx-auto text-center"
                          @click="showQuesPostFalse = true;showStartPostFalse = false;timer_start(act.type, act.settings);takePhoto();"
                        >
                          Mulai Mengerjakan
                        </v-btn>
                      </div>
                    </v-card>
                  </v-row>
                </div>
              </v-card>
            </v-row>
          </template>
          <template v-else-if="act.type == 'exam'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3 text-capitalize ms-3 text-h6">
                {{ act.title }}
              </div>

              <!-- <pre> -->
                <!-- {{ act }} -->
                <!-- {{ currentQuestionExam }} -->
                <!-- {{ optionQuestion }} -->
              <!-- </pre> -->

              <v-card v-if="act.is_complete == true || act.repeat_test > 0" class="col-md-12" elevation="0">
                <div v-show="resultNilaiExam" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="60">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="10">
                      <v-data-table
                        class="custom-table"
                        :headers="headers_nilai"
                        :items="act.section"
                        hide-default-footer
                        hide-default-sort-column>
                      </v-data-table>
                    </v-col>

                    <v-col v-if="act.settings.is_show_score === true" cols="8" class="text-center">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text mt-3">
                        <span class="text-h2 font-weight-bold">
                          {{ act.score }}
                        </span>
                      </div>
                      <div class="mt-2" v-if="act.settings.opportunity_repeat_test === -1">Kesempatan mengulang : Tanpa Batas</div>
                      <div class="mt-2" v-else-if="(act.settings.opportunity_repeat_test - act.repeat_test) === 0 || (act.settings.opportunity_repeat_test - act.repeat_test) < -1">Kesempatan mengulang : -</div>
                      <div class="mt-2" v-else>Kesempatan mengulang : {{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div>
                    </v-col>
                    <v-col md="6" sm="12">
                      <v-btn
                        v-if="act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationExam = true;
                          resultNilaiExam = false;
                          getExplanExam();
                        ">
                        Lihat Jawaban
                      </v-btn>
                      <v-spacer class="py-2"></v-spacer>
                      <div >
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectExam(act.type,act.settings)" :loading="activity_loading"
                          >Ulangi Test</v-btn
                        >
                      </div>
                      <v-spacer class="py-2"></v-spacer>
                      <v-btn v-if="act.is_complete == false" class="mx-2" color="primary" rounded block @click="getUnlockUpload()" :loading="loadUpload">
                        Klik activity selanjutnya
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="showQuesExam" class="pa-4">
                  <v-sheet
                    class="mx-auto"
                    max-width="98%"
                  >
                    <v-slide-group
                      show-arrows
                    >
                      <v-slide-item
                        v-for="index in act.total_question"
                        :key="index"
                      >
                        <v-btn
                          :class="question_status(index)"
                          x-small
                          outlined
                          class="mx-2 my-2 fs-12 py-3 text-justify"
                          style="width: 35px;"
                          elevation="0"
                          @click="goNumber(index)"
                          v-bind:id="index"
                        >
                          {{ index }}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
                  <!-- <div style="white-space: nowrap; overflow-x: auto" class="py-3">
                    <v-btn
                      v-for="index in act.total_question"
                      :key="index"
                      :class="question_status(index)"
                      x-small
                      outlined
                      class="mx-2 my-2 fs-12 py-3 text-justify"
                      style="width: 35px;"
                      elevation="0"
                      @click="goNumber(index)"
                      v-bind:id="index"
                    >
                      {{ index }}
                    </v-btn>
                  </div> -->
                  <div class="pa-4 row align-center navy-soft--text">
                    <div id="box-navigasi" class="col-md-5 d-flex">
                      <v-btn outlined small color="primary" class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                        <v-icon>$prev</v-icon></v-btn
                      >
                      <div class="mr-1 mt-2 text-center">
                        Soal no
                        <span class="primary--text">{{ act.current }}</span> dari
                        {{ act.total_question }}
                      </div>
                      <v-btn outlined small color="primary" :disabled="act.current >= act.total_question" @click="act.current++" style="min-width: auto;width: 30px!important;"
                        ><v-icon>$next</v-icon></v-btn
                      >
                    </div>
                    <div class="col-md-4 text-center">
                      <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                        {{ totalSeconds | minutesAndSeconds }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" small block color="primary" dark class="rounded-pill" @click="submitExam(act.settings)">
                            <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                            Submit
                          </v-btn>
                        </template>
                        <span>Submit</span>
                      </v-tooltip>
                      <div v-else>
                        
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>                                    
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExam.question.text">
                      </div>
                      <div v-if="currentQuestionExam.question.media != ''">    
                        <div v-if="currentQuestionExam.question.media[0].type == 'image'">
                          <v-img :src="currentQuestionExam.question.media[0].public_url"></v-img>
                        </div>      
                        <div v-else>
                          <audio-player
                            class="d-inline-block"
                            :src="currentQuestionExam.question.media[0].public_url"
                          />
                        </div>
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestionExam.answer" class="options">
                        <v-radio
                          v-for="(item, index) in optionQuestionExam"
                          :key="index"
                          class="mb-2"
                          :value="index"
                        >
                          <template v-slot:label>
                            <div style="display: block;">
                              <div v-html="item.text"></div>
                              <img width="350px" height="auto" class="mt-1 rounded-lg" :src="item.media[0].public_url" v-if="item.media.length > 0">
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                </div>
                <div v-show="showExplanationExam" class="pa-4">
                  <!-- <div style="white-space: nowrap; overflow-x: auto" class="py-3">
                      <v-btn
                        v-for="index in act.total_question"
                        :key="index"
                        :class="question_status(index)"
                        x-small
                        outlined
                        class="mx-2 my-2 fs-12 py-3 text-justify"
                        style="width: 35px;"
                        elevation="0"
                        @click="goNumber(index)"
                        v-bind:id="index"
                      >
                        {{ index }}
                      </v-btn>
                  </div> -->
                  <v-sheet
                    class="mx-auto"
                    max-width="98%"
                  >
                    <v-slide-group                      
                      show-arrows
                    >
                      <v-slide-item
                        v-for="index in act.total_question"
                        :key="index"
                      >
                        <v-btn
                          :class="question_status(index)"
                          x-small
                          outlined
                          class="mx-2 my-2 fs-12 py-3 text-justify"
                          style="width: 35px;"
                          elevation="0"
                          @click="goNumber(index)"
                          v-bind:id="index"
                        >
                          {{ index }}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn outlined small color="primary" class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.total_question }}
                    </div>
                    <v-btn outlined small color="primary" :disabled="act.current >= act.total_question" @click="act.current++" style="min-width: auto;width: 30px!important;"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          dark 
                          small
                          color="primary"
                          class="rounded-pill"
                          @click="
                            resultNilaiExam = true;
                            showExplanationExam = false;
                            act.current=1;
                          "
                        >
                          Submit
                          <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>                                                        
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                      </div>
                      <v-list v-if="act.settings.is_show_discussion == true && act.settings.is_show_answer == false" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow" color="primary">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-list v-else-if="act.settings.is_show_discussion == true && act.settings.is_show_answer == true" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text"></v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>

                                <!-- nanti di comment lagi
                                <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->

                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- nanti di comment lagi -->
                                  <!-- {{ explanation.text }} -->
                                  
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
              <v-card v-else class="col-12" elevation="0">
                <div v-show="resultNilaiExamFalse" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="10">
                      <v-data-table
                        class="custom-table"
                        :headers="headers_nilai"
                        :items="act.section"
                        hide-default-footer
                        hide-default-sort-column>
                      </v-data-table>
                    </v-col>

                    <v-col v-if="act.settings.is_show_score === true" cols="8" class="text-center">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h2 font-weight-bold mt-3">
                          {{ act.score }}
                        </span>
                      </div>
                      <div class="mt-2" v-if="act.settings.opportunity_repeat_test === -1">Kesempatan mengulang : Tanpa Batas</div>
                      <div class="mt-2" v-else-if="(act.settings.opportunity_repeat_test - act.repeat_test) === 0 || (act.settings.opportunity_repeat_test - act.repeat_test) < -1">Kesempatan mengulang : -</div>
                      <div class="mt-2" v-else>Kesempatan mengulang : {{ act.settings.opportunity_repeat_test - act.repeat_test }}x</div>
                    </v-col>
                    <v-col md="6" sm="12">
                      <v-btn
                        v-if="act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationExamFalse = true;
                          resultNilaiExamFalse = false;
                          getExplanExam();
                        ">
                        Lihat Jawaban
                      </v-btn>
                      <v-spacer class="py-2"></v-spacer>

                      <!-- nanti di comment lagi
                      <div v-if="act.type === 'Exam_test' && act.score >= act.settings.set_minimum_value.minimum_value"></div> -->


                      <div v-if="act.settings.opportunity_repeat_test === 0 || (act.settings.opportunity_repeat_test - act.repeat_test) === 0 || (act.settings.opportunity_repeat_test - act.repeat_test) < -1">

                      </div>
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectExamFalse(act.type,act.settings)" :loading="activity_loading"
                          >Ulangi Test</v-btn
                        >
                      </div>
                      <v-spacer class="py-2"></v-spacer>
                      <v-btn v-if="act.is_complete == false" class="mx-2" color="primary" rounded block @click="getUnlockUpload()" :loading="loadUpload">
                        Klik activity selanjutnya
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="showQuesExamFalse" class="pa-4">
                  <v-sheet
                    class="mx-auto"
                    max-width="98%"
                  >
                    <v-slide-group                      
                      show-arrows
                    >
                      <v-slide-item
                        v-for="index in act.total_question"
                        :key="index"
                      >
                        <v-btn
                          :class="question_status(index)"
                          x-small
                          outlined
                          class="mx-2 my-2 fs-12 py-3 text-justify"
                          style="width: 35px;"
                          elevation="0"
                          @click="goNumber(index)"
                          v-bind:id="index"
                        >
                          {{ index }}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
                  <!-- <div style="white-space: nowrap; overflow-x: auto" class="py-3">
                    <v-btn
                      v-for="index in act.total_question"
                      :key="index"
                      :class="question_status(index)"
                      x-small
                      outlined
                      class="mx-2 my-2 fs-12 py-3 text-justify"
                      style="width: 35px;"
                      elevation="0"
                      @click="goNumber(index)"
                      v-bind:id="index"
                    >
                      {{ index }}
                    </v-btn>
                  </div> -->
                  <div class="pa-4 row align-center navy-soft--text">
                    <div id="box-navigasi" class="col-md-5 d-flex">
                      <v-btn outlined small color="primary" class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                        <v-icon>$prev</v-icon></v-btn
                      >
                      <div class="mr-1 mt-2 text-center">
                        Soal no
                        <span class="primary--text">{{ act.current }}</span> dari
                        {{ act.total_question }}
                      </div>
                      <v-btn outlined small color="primary" :disabled="act.current >= act.total_question" @click="act.current++" style="min-width: auto;width: 30px!important;"
                        ><v-icon>$next</v-icon></v-btn
                      >
                    </div>
                    <div class="col-md-4 text-center">
                      <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                        {{ totalSeconds | minutesAndSeconds }}
                      </p>
                    </div>
                    <!-- nanti di comment lagi
                    <v-btn v-if="act.settings.is_skip === false" icon small :disabled="isRequired" @click="act.current++">
                      <v-icon>$next</v-icon>
                    </v-btn>
                    <v-btn v-else icon small :disabled="act.current === act.settings.number_questions_package" @click="act.current++">
                      <v-icon>$next</v-icon>
                    </v-btn> -->


                    <!-- <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                      {{ totalSeconds | minutesAndSeconds }}
                    </p> -->

                    <!-- nanti di comment lagi
                    <v-btn icon small :disabled="isRequired" @click="act.current++"
                      ><v-icon>$next</v-icon></v-btn
                    > -->


                    <div class="col-md-3">
                      <v-tooltip v-if="!review && act.current === act.total_question" color="grey darken-3" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            block
                            color="primary"
                            dark
                            small
                            class="rounded-pill"
                            @click="submitExamFalse(act.settings)"
                          >
                            <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                            Submit
                          </v-btn>
                        </template>
                        <span>Submit</span>
                      </v-tooltip>
                      <div v-else>
                        
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>                                                      
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExam.question.text"></div>
                      <div v-if="currentQuestionExam.question.media != ''">    
                        <div v-if="currentQuestionExam.question.media[0].type == 'image'">
                          <v-img :src="currentQuestionExam.question.media[0].public_url"></v-img>
                        </div>      
                        <div v-else>
                          <audio-player
                            class="d-inline-block"
                            :src="currentQuestionExam.question.media[0].public_url"                       
                          />
                        </div>              
                      </div>
                      
                      <v-radio-group hide-details="auto" v-model="currentQuestionExam.answer" class="options">
                        <v-radio
                          v-for="(item, index) in optionQuestionExam"
                          :key="index"
                          class="mb-2"
                          :value="index"
                        >
                          <template v-slot:label>
                            <div style="display: block;">
                              <div v-html="item.text"></div>
                              <img width="350px" height="auto" class="mt-1 rounded-lg" :src="item.media[0].public_url" v-if="item.media.length > 0">
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>                  
                </div>
                <div v-show="showExplanationExamFalse" class="pa-4">
                  <v-sheet
                    class="mx-auto"
                    max-width="98%"
                  >
                    <v-slide-group                      
                      show-arrows
                    >
                      <v-slide-item
                        v-for="index in act.total_question"
                        :key="index"
                      >
                        <v-btn
                          :class="question_status(index)"
                          x-small
                          outlined
                          class="mx-2 my-2 fs-12 py-3 text-justify"
                          style="width: 35px;"
                          elevation="0"
                          @click="goNumber(index)"
                          v-bind:id="index"
                        >
                          {{ index }}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
                  <!-- <div style="white-space: nowrap; overflow-x: auto" class="py-3">
                    <v-btn
                      v-for="index in act.total_question"
                      :key="index"
                      :class="question_status(index)"
                      x-small
                      outlined
                      class="mx-2 my-2 fs-12 py-3 text-justify"
                      style="width: 35px;"
                      elevation="0"
                      @click="goNumber(index)"
                      v-bind:id="index"
                    >
                      {{ index }}
                    </v-btn>
                  </div> -->
                  <div class="pa-4 d-flex align-center navy-soft--text">

                    <v-btn outlined small color="primary" class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.total_question }}
                    </div>
                    <v-btn outlined small color="primary" :disabled="act.current >= act.total_question" @click="act.current++" style="min-width: auto;width: 30px!important;"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          dark
                          small
                          color="primary"
                          class="rounded-pill"
                          @click="
                            resultNilaiExamFalse = true;
                            showExplanationExamFalse = false;
                            act.current=1;
                          "
                        >
                          Submit
                          <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>                                   
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                      </div>
                      <v-list v-if="act.settings.is_show_discussion == true && act.settings.is_show_answer == false" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow" color="primary">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-list v-else-if="act.settings.is_show_discussion == true && act.settings.is_show_answer == true" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text"></v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- nanti di comment lagi
                                <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- nanti di comment lagi
                                  {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>                  
                </div>
              </v-card>
            </v-row>
          </template>
          <template v-else-if="act.type == 'praktek_mandiri'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3">{{ act.title }}</div>
              <v-card elevation="0" style="min-height: 60vh; width: 100%">
                <br />
                <br />
                <br />
                <br />
                <div class="pa-3 text-center">
                  <p class="" v-html="act.theory.description">
                    <!-- {{ act.theory.description }} -->
                  </p>
                  <img src="@/assets/images/input-file.png" class="text-center mb-2" /><br />
                  <v-btn
                    v-if="act.is_complete === true"
                    color="primary"
                    rounded
                    class="mx-auto text-center"
                    :href="act.theory.file.url"
                    download
                    target="_blank"
                  >
                    Download
                  </v-btn>
                  <v-btn
                    v-else
                    color="primary"
                    rounded
                    class="mx-auto text-center"
                    :href="act.theory.file.url"
                    download
                    target="_blank"
                    @click="changeStatus()"
                  >
                    Download
                  </v-btn>
                </div>
              </v-card>
            </v-row>
          </template>
          <template v-else-if="act.type == 'evaluasi_praktek_mandiri'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3">{{ act.title }}</div>
              <v-col md="12" v-if="act.is_complete === true">
                <v-card elevation="0" style="min-height: 60vh; width: 100%" class="col-md-12">
                  <br />
                  <br />
                  <br />
                  <br />
                  <div class="pa-3 text-center">
                    <img src="@/assets/images/success.png" class="text-center mb-2" /><br />
                    <h3 class="font-weight-bold">
                      File berhasil diunggah
                    </h3>
                    <p class="">
                      File hasil pengerjaan tugas praktik mandiri telah berhasil diunggah
                    </p>
                  </div>
                </v-card>
              </v-col>
              <v-col md="12" v-else>
                <v-card v-show="viewUpload" elevation="0" style="min-height: 60vh; width: 100%" class="col-md-12">
                  <div class="pa-3 text-center">
                    <!-- <p class="">
                      Silahkan unggah file hasil pengerjaan tugas praktik mandiri
                    </p>
                    <img
                      src="@/assets/images/input-file.png"
                      class="text-center mb-2"
                    /><br /> -->
                    <div class="drop display-inline text-center" @dragover.prevent @drop="onDrop">
                      <div v-if="!image" class="content-img pt-12">
                        <!-- <p class="mb-2 pt-8">{{ act.title }}</p> -->
                        <div class="mb-2 pt-8" v-html="act.theory.description"></div>
                        <p class="mb-2 pt-3">
                          <img src="@/assets/images/input-file.png" class="text-center mb-2" />
                        </p>
                        <p class="mb-2 subtitle-2"></p>
                        <label class="btn-img display-inline text-center">
                          Pilih Berkas
                          <input
                            type="file"
                            name="image"
                            @change="onChange"
                            accept="image/png,application/pdf,video/mp4"
                          />
                        </label>
                        <v-alert type="info" text outlined class="col-md-11 mx-auto mt-5" dense>
                          Format file yang didukung adalah png, pdf dan mp4. Ukuran maks. 20 MB.
                        </v-alert>
                      </div>
                      <div class="hidden display-inline align-center py-4" v-else v-bind:class="{ image: true }">
                        <div class="box-img-line " style="width: 100%;margin:auto;position: relative;">
                          <!-- <p> {{ this.image }}</p> -->
                          <object :data="image" class="img-cover" />
                          <!-- <img :src="image" alt="" class="img-cover" />                     -->
                          <div class="btn-float">
                            <label class="btn bg-label mx-2" elevation="1">
                              <v-icon>
                                ri-arrow-go-back-line
                              </v-icon>
                              <input
                                type="file"
                                name="image"
                                @change="onChange"
                                accept="image/png,application/pdf,video/mp4"
                              />
                            </label>
                            <v-btn small class="mx-2 fab-white" fab @click="removeFile">
                              <v-icon dark>
                                ri-delete-bin-7-line
                              </v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <v-btn class="mx-2" color="primary" @click="submitUpload()" :loading="loadUpload">
                          Upload File
                        </v-btn>
                      </div>
                    </div>
                    <!-- <v-btn
                      color="primary"
                      rounded
                      class="mx-auto text-center"
                      :href="act.settings.attachment.url"
                      download
                      target="_blank"
                    >
                      Upload
                    </v-btn> -->
                  </div>
                </v-card>
                <v-card v-show="successUpload" elevation="0" style="min-height: 60vh; width: 100%" class="col-md-12">
                  <br />
                  <br />
                  <br />
                  <br />
                  <div class="pa-3 text-center">
                    <img src="@/assets/images/success.png" class="text-center mb-2" /><br />
                    <h3 class="font-weight-bold">
                      File berhasil diunggah
                    </h3>
                    <p class="">
                      File hasil pengerjaan tugas praktik mandiri telah berhasil diunggah
                    </p>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="act.type == 'berdiskusi_daring'">
            <!-- <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3">{{ act.title }}</div>
              <v-card elevation="0" class="col-md-12">
                <div class="pa-4">
                  <div class="pa-4">
                    <p class="text-body-1  text-justify mb-4" v-html="this.act.theory.description"></p>
                    <div v-for="(item, index) in this. act.theory.meeting.days" :key="index">
                      <p>Hari {{ day(item.day) }} , <span v-for="(times, index2) in item.time" :key="index2">pukul {{ times.start_time }} - {{times.end_time}} WIB</span></p>                                            
                    </div>
                    <p v-if="act.theory.meeting.link != ''" class="mb-1">Link : </p>
                    <a :href="this.act.theory.meeting.link" target="_blank" v-on:click="changeStatus()" class="">{{act.theory.meeting.link}}</a>
                    <div v-if="act.theory.meeting.link != ''" class="mt-3">
                      <div v-for="(item, index) in this. act.theory.meta_data" :key="'A'+index">
                        <p>{{ item.title }} : {{ item.content }}</p>                                            
                      </div>
                    </div>                    
                  </div>
                </div>
              </v-card>
            </v-row> -->
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3">{{ act.title }}</div>
              <v-card elevation="0" class="col-md-12">
                <div class="pa-4">
                  <div class="pa-4">
                    <p class="text-body-1  text-justify mb-4" v-html="this.act.theory.description"></p>
                    <div v-if="this.act.theory.meeting.days != null ">                       
                      <div v-for="(item, index) in this.act.theory.meeting.days" :key="index">
                        <p>Hari {{ day(item.day) }} , <span v-for="(time, index2) in item.times" :key="index2">pukul {{ time.start_time }} - {{time.end_time}} WIB</span></p>                                            
                      </div>
                    </div>
                    <div v-if="this.act.theory.meeting.dates != null ">                      
                      <div v-for="(item, index) in this.act.theory.meeting.dates" :key="index">
                        <p>Tanggal {{ currentDateTime(item.date) }} , <span v-for="(time, index) in item.times" :key="index+'T'">pukul {{ time.start_time }} - {{time.end_time}} WIB</span></p>                                            
                      </div>
                    </div>
                    <p v-if="act.theory.meeting.link != ''" class="mb-1">Link : </p>
                    <!-- <a :href="this.act.theory.meeting.link" target="_blank" v-on:click="changeStatus()" class="btn btn-primary">Klik disini</a> -->
                    <v-btn
                      v-if="act.theory.meeting.link != ''"              
                      small
                      color="primary"
                      dark
                      class="white px-2"
                      :href="this.act.theory.meeting.link" target="_blank" @click="changeStatus()"
                    >
                      Klik disini
                    </v-btn>
                    <!-- <a href="#" target="_blank" v-on:click="changeStatus()" class="">testtttt</a> -->
                    <div v-if="act.theory.meeting.link != ''" class="mt-3">
                      <div v-for="(item, index) in this. act.theory.meta_data" :key="'A'+index">
                        <p>{{ item.title }} : {{ item.content }}</p>                                            
                      </div>
                    </div>                    
                  </div>
                </div>
              </v-card>
            </v-row>
          </template>
          <template v-else-if="act.type == 'evaluasi'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3">{{ act.title }}</div>

              <v-card elevation="0" class="col-md-12">
                <div class="pa-4">
                  <div class="pa-4">
                    <p class="text-body-1  text-justify mb-4" v-html="this.act.theory.description"></p>
                  </div>
                </div>
              </v-card>
            </v-row>
          </template>
          <template v-else-if="act.type == 'membaca-e-book'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3">{{ act.title }}</div>
              <v-card elevation="0" style="height: 100vh; width: 100%">
                <div v-if="isLoadingEmbedPDF">
                  <v-progress-linear
                    indeterminate
                    color="primary"
                  ></v-progress-linear>
                </div>
                <vue-pdf-embed 
                  annotationLayer textLayer 
                  :source="act.theory.file.url" 
                  style="height: 90vh; width: 100%; overflow-y: scroll"
                  @rendered="handleDocumentRender"
                />
              </v-card>
            </v-row>
          </template>
          <template v-else>
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp" class="bg-white">
              <v-col cols="12" class="pa-0">
                <v-card elevation="0">
                  <img :src="cover.url" style="width: 100%; height: 300px; object-fit: cover" />
                </v-card>
              </v-col>
              <v-tabs id="" v-model="tabs">
                <v-tab class="text-capitalize">Deskripsi</v-tab>
                <v-tab class="text-capitalize">Review</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabs" class="setting col-md-12">
                <v-tab-item>
                  <v-card elevation="0" class="col-md-12">
                    <div class="fs-fix">
                      <!-- <h3 class="mb-2 pa-2">Deskripsi Pelatihan</h3> -->
                      <vue-show-more-text
                        :text="program.program_benefits"
                        :lines="10"
                        :more-text="more"
                        :less-text="less"
                        additional-content-css="font-size:0.875rem !important;"
                        additional-content-expanded-css="font-size:0.875rem !important;"
                        additional-anchor-css="font-size: 0.875rem !important;font-weight:bold;color:#f05326!important; text-align: center!important;"
                        @click="change"
                      />
                      <h3 class="pa-2">Bidang Studi</h3>
                      <p class="pa-2 text-capitalize fs-fix">
                        {{ this.studies.title }}
                      </p>

                      <h3 class="pa-2">Lembaga Pengajar</h3>
                      <div class="row">
                        <div class="col-md-3">
                          <img src="@/assets/images/logo.png" style="width: 100%;" />
                        </div>
                        <div class="col-md-9">
                          <p class="pa-2 text-capitalize fs-fix">
                            Kreasi Belajar Merupakan Platform Pembelajaran Daring (Online Learning) Berupa Perangkat Lunak Berbasis Web Yang Dapat Digunakan Untuk Mendukung Kegiatan Belajar Mengajar. Edulink Juga Dapat Diakses Secara Online Untuk Mendukung Kegiatan Pembelajaran Tatap Muka, Bahkan Untuk Menggantikan Peran Ruang Kelas Dan Memungkinkan Terjadinya Interaksi Antara Pengajar Dan Pelajar Melalui Jaringan Internet.
                          </p>
                        </div>
                      </div>

                      <h3 class="pa-2 mb-2">Fasilitator</h3>
                      <div class="row">
                        <div v-for="(item, index) in this.program.fasilitator" :key="'A' + index">
                          <div class="col-md-12 ma-auto">
                            <img
                              :src="item.picture.url"
                              class="mx-2 text-center"
                              style="width: 120px;height: 120px;border-radius: 50%;object-fit: cover;"
                            />
                          </div>
                        </div>
                        <div class="col-md-12" v-for="(item, index) in this.fasilitator" :key="'B' + index">
                          <p class="pa-2 text-capitalize mb-0 fs-fix" v-html="item.description">
                            <!-- {{ item.description }} -->
                          </p>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card elevation="0" class="col-md-12" v-show="formUlasan">
                    <h3>Ulasan Program</h3>
                    <v-rating v-model="rating" background-color="primary" color="primary" size="50"></v-rating>
                    <v-col cols="12" md="12" class="">
                      <v-textarea solo name="input-7-4" label="Berikan ulasan anda" v-model="ulasan"></v-textarea>
                      <v-btn color="primary" class="btn-beli ms-auto" elevation="2" :loading="subSend" @click="send()">
                        Submit
                      </v-btn>
                    </v-col>
                  </v-card>
                  <div>
                    <div class="rate col-md-4 text-center">
                      <v-card class="pa-2" color="primary">
                        <h2 class="font-weight-bold display-2">{{ this.rate.rating }}</h2>
                        <v-rating
                          v-model="this.rate.rating"
                          color="white"
                          background-color="grey darken-1"
                          empty-icon="$ratingFull"
                          half-increments
                          hover
                          small
                          readonly
                        ></v-rating>
                        <p class="text-muted fs-14 font-weight-bold ">{{ this.rate.count }} Review</p>
                      </v-card>
                    </div>
                    <div class="col-md-4"></div>
                    <v-col md="12">
                      <v-list three-line id="detail-kelas">
                        <template>
                          <v-list-item v-for="(item, index) in this.listReview" :key="'X' + index">
                            <v-list-item-avatar>
                              <img src="@/assets/images/no-pict.png" />
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{ item.member.fullname }}</v-list-item-title>
                              <div>
                                <v-icon color="yellow" small v-for="index in item.star" :key="'Y' + index"
                                  >mdi-star</v-icon
                                >
                              </div>
                              <v-list-item-subtitle>{{ item.review }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-col>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-row>
          </template>
        </v-col>
        <v-slide-x-reverse-transition>
          <v-col v-if="isOpen" cols="12" sm="4" :style="responsiveStyle">
            <v-row v-if="!$vuetify.breakpoint.smAndUp" no-gutters>
              <v-btn class="pl-1 pr-2 mb-2" text color="primary" @click="showActivity = false">
                <v-icon class="mr-2">$prev</v-icon>
                Kembali
              </v-btn>
            </v-row>
            <p
              text
              height="32"
              class="pa-0 my-2 font-weight-bold text-capitalize"
              @click="
                act = {};
                act_active = null;
                topics_active = null;
              "
              style="cursor: pointer; text-decoration: none"
            >
              {{ program.title }}
            </p>
            <div class="rounded white py-4 mb-3">
              <div class="px-4 mb-2">
                <div class="d-flex align-center mb-2">
                  <v-progress-linear
                    class="mr-2"
                    color="primary"
                    background-color="grey lighten-3"
                    height="6"
                    rounded
                    :value="this.detailClass.results.progress"
                  ></v-progress-linear>
                  <div class="navy-soft--text">{{ this.detailClass.results.progress }}%</div>
                </div>
                <v-tabs id="" v-model="tab" background-color="transparent">
                  <v-tab class="text-capitalize">Aktivitas</v-tab>
                  <v-tab class="text-capitalize">Sertifikat</v-tab>
                </v-tabs>
                <!-- <div class="font-weight-bold">Aktifitas</div> -->
              </div>
              <v-tabs-items v-model="tab" class="setting transparent">
                <v-tab-item>
                  <div>
                    <v-expansion-panels
                      class="custom-panels overflow-auto"
                      style="max-height: 64vh"
                      v-model="topics_active"
                    >
                      <!-- :disabled="this.detailClass.results.progress ===100 && this.scorePost >= this.setMinim.minimum_value" -->
                      <v-expansion-panel class="rounded-0" v-for="(item, index) in topics" :key="index">
                        <v-expansion-panel-header class="px-4">
                          <div>
                            <div class="mb-1 font-weight-bold">{{ item.name }}</div>
                            <div class="text-body-2 color-navy-soft">{{ item.activities.length }} Aktivitas</div>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="navy-soft pa-0">
                          <v-list dense class="col-12 pa-0">
                            <v-list-item-group v-model="act_active">
                              <v-divider></v-divider>
                              <v-list-item
                                v-for="(act, i) in item.activities"
                                :key="i"
                                class="grey lighten-5 d-block"
                                @click="
                                  getAct(act, item.id);
                                  showActivity = false;
                                  this.timer_stop();
                                  this.totalSeconds=0;
                                "
                                :disabled="act.is_lock === true"
                              >
                                <!-- act_lock_disabled -->
                                <!-- pageType = act; -->
                                <v-list-item-content>
                                  <div class="d-flex my-1">
                                    <v-icon
                                      v-if="act.is_complete === true"
                                      class="mr-1 mt-0"
                                      color="green darken-3"
                                      small
                                      >ri-checkbox-circle-fill</v-icon
                                    >
                                    <div style="width: 2rem">{{ i + 1 }}.</div>
                                    <div>
                                      <div
                                        class="
                                        mb-1
                                        font-weight-medium
                                        text-capitalize
                                      "
                                      >
                                        {{ act.title }}
                                      </div>
                                      <div class="color-navy-soft text-body-2 d-flex">
                                        <template v-if="act.type == 'menonton_video'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-film-line</v-icon>
                                            Menonton Video
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            00:{{ act.settings.estimated_duration | minute }}:00
                                          </span>
                                        </template>
                                        <template
                                          v-else-if="
                                            act.type == 'pre_test' || act.type == 'post_test' || act.type == 'quiz'
                                          "
                                        >
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-survey-line</v-icon>
                                            Mengerjakan Soal
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{ dateTime(act.settings.estimated_duration)}}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'praktek_mandiri'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-file-download-line</v-icon>
                                            Praktek Mandiri
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{ dateTime(act.settings.estimated_duration)}}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'evaluasi_praktek_mandiri'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-file-upload-line</v-icon>
                                            Evaluasi Praktek Mandiri
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{ dateTime(act.settings.estimated_duration)}}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'berdiskusi_daring'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-survey-line</v-icon>
                                            Berdiskusi Daring
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{ dateTime(act.settings.estimated_duration)}}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'evaluasi'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-edit-box-line</v-icon>
                                            Evaluasi
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{ dateTime(act.settings.estimated_duration)}}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'membaca-e-book'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-file-list-line</v-icon>
                                            Membaca E-Book
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{ dateTime(act.settings.estimated_duration)}}
                                          </span>
                                        </template>
                                      </div>
                                    </div>
                                  </div>
                                </v-list-item-content>
                                <v-divider
                                  style="
                                  position: absolute;
                                  left: 0;
                                  right: 0;
                                  bottom: 0;
                                "
                                ></v-divider>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <v-divider class="mb-2"></v-divider>
                  <div class="col-md-10 mx-auto">
                    <!-- <div>                      
                      <v-btn
                        color="primary"
                        rounded
                        class="mx-auto text-center btn-beli mb-2"
                        block
                        elevation="2"
                        :disabled="this.detailClass.results.progress !== 100"
                        :to="{ name: 'certificate-participant', params: { id: this.detailClass.license } }"
                        target="_blank"
                      > 
                        Sertifikat Penyelesaian
                      </v-btn>
                    </div> -->
                    <div>                      
                      <v-btn
                        color="primary"
                        rounded
                        class="mx-auto text-center btn-beli"
                        block
                        elevation="2"
                        :disabled="isDisable"
                        :to="{ name: 'certificate-graduation', params: { id: this.detailClass.license } }"
                        target="_blank"
                      >
                        Sertifikat Kompetensi 
                      </v-btn>
                      <!-- <v-btn
                        v-else
                        color="primary"
                        rounded
                        class="mx-auto text-center btn-beli"
                        block
                        elevation="2"
                        :disabled="isDisable"
                        :href="this.generate.url"
                        :loading="loadCertif"
                        target="_blank"
                      >
                        Sertifikat Kompetensi 
                      </v-btn> -->
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
        </v-slide-x-reverse-transition>
      </v-row>
    </v-container>
    <v-snackbar v-model="notification.state" top>
      {{ notification.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" icon v-bind="attrs" @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- # DIALOG DETECT NO FACE -->
    <v-dialog v-model="dialog_camera" persistent width="650">
      <v-card flat>
        <v-card-title style="color: #3C4858" class="font-weight-bold d-flex justify-center align-center">
          Kamera Belum Aktif
        </v-card-title>
        <v-card-text class="text-center">
          Sistem tidak mendeteksi kamera anda, pastikan kamera anda aktif selama tes berlangsung
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <!-- <v-btn 
            large
            @click="dialog_camera=false" 
            color="primary">
            Baik, Saya Mengerti
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col v-show="showCam" cols="12" class="pa-0">
        <div class="my-3">
          <video
            ref="video"
            class="test-video"
            id="video"
            width="240"
            height="140"
            autoplay>
          </video>
          <canvas ref="canvas" />
          <canvas
            v-show="isPhotoTaken"
            id="photoTaken"
            width="240"
            height="140"
            class="mr-2"
          ></canvas>
          <canvas
            v-show="isPhotoTaken2"
            id="photoTaken2"
            ref="canvas2"
            width="240"
            height="140"
          ></canvas>
          <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>
          <div class="camera-shutter" :class="{ flash: isShotPhoto2 }"></div>
        </div>
    </v-col>
  </v-row>
</template>
<script src="http://www.youtube.com/player_api"></script>
<script>
import VideoPlayer from "@/components/VideoPlayer.vue";
import vueShowMoreText from "vue-show-more-text";
// import VideoPlayer from "../../components/VideoPlayer.vue";
import VueYoutube from "vue-youtube";
import moment from "moment";
// import VueHtml2pdf from "vue-html2pdf";
import { mapGetters } from "vuex";

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: "detail-class",
  components: {
    vueShowMoreText,
    VideoPlayer,
    'vue-pdf-embed': VuePdfEmbed
  },
  filters: {
    minutesAndSeconds(value) {
      var hours = `${Math.floor(value / 3600)}`.padStart(2, 0);
      var minutes = `${Math.floor(value / 60) % 60}`.padStart(2, 0);
      var seconds = `${value % 60}`.padStart(2, 0);
      return `${hours} : ${minutes} : ${seconds}`;
    },
    minute: (val) => String(val).padStart(2, 0),
  },
  data: () => {
    return {
      videoOptions: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src: null,
            type: "video/mp4",
          },
        ],
      },
      headers_nilai: [
        { text: "Jenis Test", value: "name", align: "start", sortable: false },
        { text: "Nilai Minimal", value: "min_score", align: "center", sortable: false },
        { text: "Hasil Nilai Test", value: "score", align: "center", sortable: false },
      ],
      tab: null,
      tabs: null,
      loadContent: false,
      content: false,
      detailClass: {},
      notification: {},
      act: {},
      cover: {},
      fasilitator: {},
      studies: {},
      id_act: "",
      id_topic: "",
      isLoading: false,
      isLoadingEmbedPDF: true,
      // result: null,
      review: null,
      showActivity: null,
      activity_loading: null,
      act_active: null,
      topics_active: null,

      successUpload: false,
      viewUpload: true,
      image: null,
      file: "",
      loadUpload: false,

      resultNilai: false,
      showQues: true,
      showExplanation: false,

      resultNilaiFalse: false,
      showQuesFalse: true,
      showExplanationFalse: false,

      // pre
      showExplanationPre: false,
      showExplanationPreFalse: false,

      //post
      showExplanationPost: false,
      showExplanationPostFalse: false,

      //exam
      resultNilaiExam: false,
      showQuesExam: true,
      showExplanationExam: false,

      resultNilaiExamFalse: false,
      showQuesExamFalse: true,
      showExplanationExamFalse: false,

      more: "Lihat Selengkapnya",
      less: "Sembunyikan",

      playerVars: {
        controls: 0,
      },
      player: {},
      psr: "peserta",
      kom: "kompetensi",
      typeCertif: "",
      listRandom: null,
      randomQuiz: null,

      subSend: false,

      rating: 0,
      ulasan: "",

      scorePost: "",
      setMinim: {},
      certifP: {},
      certifL: {},
      generate:{},
      generateP:{},
      rate: {},
      listReview: {},
      id_pro: "",
      timeUpdate: 0,

      formUlasan: false,
      loadCertif:false,

      activity_id_active:'',
      topic_id_active:'',

      resultNilaiPost: true,
      showQuesPost: false,
      showExplanationPost: false,
      showStartPost:false,

      resultNilaiPostFalse: false,
      showQuesPostFalse: false,
      showExplanationPostFalse: false,
      showStartPostFalse:false,

      resultNilaiQuiz: false,
      showQuesQuiz: false,
      showExplanationQuiz: false,
      showStartQuiz:false,

      resultNilaiQuizFalse: false,
      showQuesQuizFalse: false,
      showExplanationQuizFalse: false,
      showStartQuizFalse:false,

      timer: {},
      totalSeconds: null,
      interval: {},
      timerDialog: "",

      setting:'',

      isPhotoTaken: false,
      isShotPhoto: false,
      isPhotoTaken2: false,
      isShotPhoto2: false,
      dialog_camera:false,
      showCam:false,
      images_capture:[]

    };
  },
  created() {
    this.getDetailClass();
  },
  mounted: function() {
    window.onYouTubeIframeAPIReady = () => {
      this.player = new YT.Player("vid", {
        events: {
          onReady: window.onPlayerReady,
          onStateChange: window.onPlayerStateChange,
        },
      });
      console.log(this.player);
    };

    window.onPlayerReady = (event) => {
      event.target.playVideo();
    };

    window.onPlayerStateChange = (event) => {
      this.stateChanged(event);
    };
  },
  computed: {
    ...mapGetters(["user"]),
    isOpen() {
      return this.$vuetify.breakpoint.smAndUp || this.showActivity;
    },
    isDisable() {
      return this.detailClass.results.progress !== 100 || this.scorePost < this.setMinim.minimum_value;
    },
    isRequired(){
      return (this.currentQuestion.answer === null || this.currentQuestion.answer === undefined) || this.act.current === this.act.settings.number_questions_package  ;
      // return this.act.current >= this.act.settings.number_questions_package || this.currentQuestion.answer == null;
    },
    isRequiredExam(){
      return (this.currentQuestionExam.answer === null || this.currentQuestionExam.answer === undefined) || this.act.current === this.act.settings.number_questions_package  ;
      // return this.act.current >= this.act.settings.number_questions_package || this.currentQuestion.answer == null;
    },
    result_exam() {
      let graduated = true;
      // let a= [
      //   {
      //     id: "62b17791e152f959361a2041",
      //     min_score: 80,
      //     name: "TKP",
      //     score: 80,
      //   },
      //   {
      //     id: "62b17791e152f959361a2042",
      //     min_score: 10,
      //     name: "TKP",
      //     score: 1,
      //   },
      //   {
      //     id: "62b17791e152f959361a2043",
      //     min_score: 10,
      //     name: "TKP",
      //     score: 25,
      //   }
      // ]
      this.act.section.forEach((item) => {
        if (item.score < item.min_score) {
          graduated = false
        }
      })
      return graduated

    },
    responsiveStyle() {
      return this.$vuetify.breakpoint.smAndUp
        ? ""
        : {
            position: "absolute",
            backgroundColor: "#eff2f7",
            height: "100vh",
            overflow: "auto",
          };
    },
    program() {
      return this.detailClass.program || {};
    },
    topics() {
      return this.detailClass.topics || [];
    },
    // progress() {
    //   const complete = this.topics.filter((val) => val.is_complete);

    //   return ((complete.length / this.topics.length) * 100).toFixed() || 0;
    // },
    questions() {
      try {
        return this.act.list_question.reduce((cur, val) => [...cur, ...val.list_question], []);
      } catch (error) {
        return [];
      }
    },
    questionsExam() {
      try {
        let result = []
        // .reduce((cur, val) => [...cur, ...val.list_question], [])
        this.act.list_question_exam.forEach((value) => {
          value.groups.forEach((val) => {
            result = [...result, ...val.list_question]
          })
        })
        // console.log('result',result);
        return result;
      } catch (error) {
        return [];
      }
    },
    questionsExplan() {
      try {
        return this.act.list_question_explan;
        // return this.act.list_question_explan.reduce((cur, val) => [...cur, ...val.test], []);
      } catch (error) {
        return [];
      }
    },
    currentQuestion() {
      try {
        const index = this.act.current - 1;
        return this.questions[index];
      } catch (error) {
        return {};
      }
    },
    currentQuestionExam() {
      try {
        const index = this.act.current - 1;
        // console.log('exam',this.questionsExam[index].question)
        return this.questionsExam[index];
        // return this.questionsExam[index].question;
      } catch (error) {
        return {};
      }
    },
    optionQuestionExam() {
      try {
        const index = this.act.current - 1;
        return this.questionsExam[index].options;
      } catch (error) {
        return {};
      }
    },
    currentQuestionExplan() {
      try {
        const index = this.act.current - 1;
        // console.log('question active',this.questionsExplan[index])
        return this.questionsExplan[index].test.question;        
      } catch (error) {
        return {};
      }
    },
    optionQuestionExplan() {
      try {
        const index = this.act.current - 1;
        // console.log('question active',this.questionsExplan[index].test.question)
        return this.questionsExplan[index].test.options;        
      } catch (error) {
        return {};
      }
    },
    answerTextExplan() {
      try {
        const index = this.act.current - 1;
        // console.log('question active',this.questionsExplan[index].answer[0])
        return this.questionsExplan[index].answer[0];        
      } catch (error) {
        return {};
      }
    },
    correctTextExplan() {
      try {
        const index = this.act.current - 1;
        // console.log('question active',this.questionsExplan[index].correct_answer[0])
        return this.questionsExplan[index].correct_answer[0];        
      } catch (error) {
        return {};
      }
    },
    answerExplan() {
      try {
        const index = this.act.current - 1;
        // console.log('question active',this.questionsExplan[index].is_answered)
        return this.questionsExplan[index].is_answered;        
      } catch (error) {
        return {};
      }
    },
    correctAnswer() {
      try {
        const index = this.act.current - 1;
        // console.log('question active',this.questionsExplan[index].is_correct)
        return this.questionsExplan[index].is_correct;        
      } catch (error) {
        return {};
      }
    },
    explanation() {
      // return this.questionsExplan.test.explanation.correct || {};
      try {
        const index = this.act.current - 1;
        // console.log('question active',this.questionsExplan[index].test.question)
        return this.questionsExplan[index].test.explanation.correct;        
      } catch (error) {
        return {};
      }
    },
    // question(){
    //   return this.currentQuestion.question || {};
    // }
  },
  methods: {
    handleDocumentRender() {
      this.isLoadingEmbedPDF = false;
    },
    ended() {
      console.log("end watch");
      this.getUnlock(this.topics[this.topics_active].id, this.act.id);
    },
    change(showAll) {
      console.log(showAll);
    },
    getDetailClass() {
      this.loadContent = true;
      this.axios.get(`/users/v1/myprogram/detail/${this.$route.params.id}`).then((response) => {
        this.content = true;
        this.loadContent = false;
        let res = response.data.data;
        this.detailClass = res;
        this.fasilitator = res.program.fasilitator;
        this.id_pro = res.program;
        this.cover = res.program.program_information.cover;
        this.studies = res.program.learning_targets.studies;
        this.scorePost = res.results.scores.post_test.score;
        this.setMinim = res.results.scores.post_test.set_minimum_value;
        this.certifP = res.results.certificates.participant;
        this.certifL = res.results.certificates.graduation;

        this.getReviewClass();
        if (res.results.progress === 100) {
          this.detailReview(res.license, this.id_pro.id);
        }
        // if (this.certifP === undefined) {
        //   this.getCertif(res.id,res.results.scores.post_test.score,res.results.scores.post_test.set_minimum_value);
        // }
        // if (this.certifL === undefined) {
        //   this.getCertif(res.id,res.results.scores.post_test.score,res.results.scores.post_test.set_minimum_value);
        // }
      });
    },
    getCertif(id,a,b){
      // console.log('bisa',this.certifL.id );
      if (this.detailClass.is_complete === true && this.certifL.id === undefined) {        
        if (a >= b.minimum_value) {
          this.loadCertif=true
          // console.log('bisa');          
            const data = {
              id: id,
            };
            this.axios
              .post(`/users/v1/myprogram/generate-certificate`, data)
              .then((response) => {
                this.loadCertif=false
                let res = response.data.data;
                this.generate= res.graduation;
                // this.getDetailClass();                              
              })
              .catch((error) => {
                console.log(error)
                this.loadCertif=false                
              });
        }              
      }
      else if (this.detailClass.is_complete === true && this.certifP.id === undefined) { 
        this.loadCertif=true        
          const data = {
              id: id,
            };
            this.axios
              .post(`/users/v1/myprogram/generate-certificate`, data)
              .then((response) => {
                this.loadCertif=false
                let res = response.data.data;
                this.generateP= res.participant;
                // this.getDetailClass();                              
              })
              .catch((error) => {
                console.log(error)
                this.loadCertif=false                
              });
        }

    },
    detailReview(voucher, id) {
      const data = {
        license: voucher,
        program_id: id,
      };
      this.axios
        .post(`/program_review/v1/detail`, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          this.formUlasan=false;
        })
        .catch((error) => {
          console.error(error);
          if (this.detailClass.results.progress != 100) {
            this.formUlasan = false;
          }
          else{
            this.formUlasan=true;
          }
        });
    },
    getReviewClass() {
      this.axios
        .get(`/program_review/v1/list?program_id=${this.id_pro.id}&sort=created_at&sort_type=desc&is_publish=1`, {
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY },
        })
        .then((response) => {
          this.content = true;
          this.loadContent = false;
          let res = response.data.data;
          this.listReview = res.list;
          this.rate = res.rating;
        });
    },
    getAct(act, id) {
      this.id_topic = id;
      this.id_act = act.id;
      this.isLoading = true;
      
      this.axios
        .get(`/users/v1/myprogram/detail_activity/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
        .then(async (response) => {
          let res = response.data.data;
          this.act = res;
          this.getDetailClass();
          // console.log('topic',id)
          // console.log('act',act.id)
          if (this.act.type == "pre_test") {
            // this.topic_id_active = id;
            // this.activity_id_active = act.id;
            this.setting = this.act.settings;
            if (this.setting.is_camera_active == true) {
              this.startStream()
            }
            if (this.act.is_complete) {
              this.resultNilai = this.resultNilaiFalse = true;
              this.showQues = this.showQuesFalse = false;
            } else {
              this.repeat();
              this.repeatFalse();
            }
            this.$set(this.act, "current", 1);
            await this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {                
                if(this.act.settings.is_random_question===true){
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question", this.listRandom);
                  await this.setQuestion();
                }else{
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();                  
                }
              });
            return;
          } else if (this.act.type == "post_test") {
            // this.topic_id_active = id;
            // this.activity_id_active = act.id;
            this.setting = this.act.settings;
            if (this.setting.is_camera_active == true) {
              this.startStream()
            }
            if (this.act.is_complete) {
              this.resultNilaiPost = this.resultNilaiPostFalse = true;
              this.showQuesPost = this.showQuesPostFalse = false;
              this.showStartPost = this.showStartPostFalse =false;                            
            } 
            else {
              this.repeatPost(this.act.settings);
              this.repeatFalsePost(this.act.settings);
            }
            this.showExplanationPost=this.showExplanationPostFalse=false;

            this.$set(this.act, "current", 1);
            await this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {                
                if(this.act.settings.is_random_question===true){
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question", this.listRandom);
                  await this.setQuestion();
                }else{
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();                  
                }
              });
            return;
          } else if (this.act.type == "exam") {
            // console.log('exam', this.act);
            if(this.act.is_lock === false){
              this.activity_unlock=false;
            } else {
              this.activity_unlock=true
            }
            // console.log('score',this.act.score)
            if (this.act.is_complete == true || this.act.repeat_test > 0) {
              this.resultNilaiExam = this.resultNilaiExamFalse = true;
              this.showQuesExam = this.showQuesExamFalse = false;
            } 

            this.$set(this.act, "current", 1);
            this.activity_loading=true;
            await this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {   
                // console.log('getActFalse', res.data);
                if(this.act.settings.is_random_question===true){
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question_exam", this.listRandom);
                  // console.log('this.act.list_question_exam', this.act.list_question_exam);
                  await this.setQuestionExam();
                  if (this.act.settings.is_show_timer === true && this.act.score === 0 ) {
                    this.timer_start(this.act.type,this.act.settings);
                  }
                } else {
                  this.$set(this.act, "list_question_exam", res.data);
                  // console.log('this.act.list_question_exam false', this.act.list_question_exam);
                  await this.setQuestionExam(); 
                  if (this.act.settings.is_show_timer === true && this.act.score === 0) {
                    this.timer_start(this.act.type,this.act.settings);
                  }
                }
              });
            return;
          } else if (this.act.type == "quiz") {
            this.setting=this.act.settings;
            if (this.setting.is_camera_active == true) {
              this.startStream()
            }
            if (this.act.is_complete) {
              this.resultNilaiQuiz = this.resultNilaiQuizFalse = true;
              this.showQuesQuiz = this.showQuesQuizFalse = false;
              this.showStartQuiz = this.showStartQuizFalse =false;
            } 
            else {
              this.repeatQuiz(this.act.settings);
              this.repeatQuizFalse(this.act.settings);
            }
            this.showExplanationQuiz=this.showExplanationQuizFalse=false;
            this.$set(this.act, "current", 1);
            await this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {                
                if(this.act.settings.is_random_question===true){
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.randomQuiz = shuffle;
                  this.$set(this.act, "list_question", this.randomQuiz);
                  await this.setQuestion();
                } else {
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();
                }
              });
            return;
          } else if (this.act.type == "evaluasi") {
            this.changeStatus();
          } else if (this.act.type == "menonton_video") {
            this.$set(this.videoOptions.sources[0], "src", this.act.theory.file.url);
            // this.$refs.videoPlayer.$forceUpdate();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    getActFalse(topic, act) {
      this.id_topic = topic;
      this.id_act = act;
      this.isLoading = true;
      this.axios
        .get(`/users/v1/myprogram/detail_activity/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
        .then(async (response) => {
          let res = response.data.data;
          this.act = res;
          this.getDetailClass();

          if (this.act.type == "pre_test") {

            this.setting = this.act.settings;
            if (this.setting.is_camera_active == true) {
              this.startStream()
            }
            if (this.act.is_complete) {
              this.resultNilai = this.resultNilaiFalse = true;
              this.showQues = this.showQuesFalse = false;
            } 
            // else {
            //   this.repeat();
            //   this.repeatFalse();
            // }            
            this.$set(this.act, "current", 1);
            await this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {                
                if(this.act.settings.is_random_question===true){
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question", this.listRandom);
                  await this.setQuestion();
                }else{
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();                  
                }
              });
            return;
          } else if (this.act.type == "post_test") {
            // this.topic_id_active = id;
            // this.activity_id_active = act.id;
            this.setting = this.act.settings;
            if (this.setting.is_camera_active == true) {
              this.startStream()
            }
            if (this.act.is_complete) {
              this.resultNilaiPost = this.resultNilaiPostFalse = true;
              this.showQuesPost = this.showQuesPostFalse = false;
              this.showStartPost = this.showStartPostFalse =false;              

            } 
            // else {
            //   this.repeatPost();
            //   this.repeatFalsePost();
            // }
            this.showExplanationPost=this.showExplanationPostFalse=false;

            this.$set(this.act, "current", 1);
            await this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {                
                if(this.act.settings.is_random_question===true){
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question", this.listRandom);
                  await this.setQuestion();
                }else{
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();                  
                }
              });
            return;
          } else if (this.act.type == "exam") {
            // console.log('exam', this.act);
            if(this.act.is_lock === false){
              this.activity_unlock=false;
            }else{
              this.activity_unlock=true
            }
            if (this.act.is_complete == true || this.act.repeat_test > 0) {
              this.resultNilaiExam = this.resultNilaiExamFalse = true;
              this.showQuesExam = this.showQuesExamFalse = false;
            } 

            this.$set(this.act, "current", 1);
            this.activity_loading=true;
            await this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {   

                if(this.act.settings.is_random_question===true){
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question_exam", this.listRandom);
                  // console.log('this.act.list_question_exam', this.act);
                  await this.setQuestionExam();
                }else{
                  this.$set(this.act, "list_question_exam", res.data);
                  // console.log('this.act.list_question_exam false', this.act);
                  await this.setQuestionExam();                  
                }
              });
            return;
          } else if (this.act.type == "quiz") {
            this.setting = this.act.settings;
            if (this.setting.is_camera_active == true) {
              this.startStream()
            }
            if (this.act.is_complete) {
              this.resultNilaiQuiz = this.resultNilaiQuizFalse = true;
              this.showQuesQuiz = this.showQuesQuizFalse = false;
              this.showStartQuiz = this.showStartQuizFalse =false;              
            } 
            // else {
            //   this.repeat();
            //   this.repeatFalse();
            // }            
            this.showExplanationQuiz=this.showExplanationQuizFalse=false;            
            this.$set(this.act, "current", 1);
            await this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                if(this.act.settings.is_random_question===true){
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.randomQuiz = shuffle;
                  this.$set(this.act, "list_question", this.randomQuiz);
                  await this.setQuestion();
                }else{
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();                  
                }
              });
            return;
          } else if (this.act.type == "evaluasi") {
            this.changeStatus();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    // CAMERA STREAM
    startStream() {
      // console.log('settings',this.settings.is_camera_active)
      // this.showDetector = true
      // this.showIconFace = true
      if(this.setting.is_camera_active == true){
        this.showCam=true;
        this.dialog_camera=true;
        // await this.axios.get(`/institusion/v1/get/institusion/detail/${this.user.institusion.id}`).then((response) => {
        //   let res = response.data;
        //   if (res.status == 200) {
        //     this.lembaga = res.data
          // setTimeout(() =>{
            // if (this.lembaga.active_camera) {
              this.$route.meta.prevent_move = true;
              navigator.mediaDevices
              .getUserMedia({ video: true })
              .then((mediaStream) => {
                this.$refs.video.srcObject = mediaStream;
                this.$refs.video.play();
                this.dialog_camera=false;
                this.showCam=true;
              }).catch((error) => {
                // this.showDetector = false
                // this.showIconFace = false
                this.$refs.video.pause();
                this.showCam=true;
                this.dialog_camera=true;
                console.error("getUserMedia() error:", error);
              });
            // }else{
            //   this.showDetector = false
            //   this.showIconFace = false
            //   this.$refs.video.pause();
            // }
          // }, 2000)
        //   } else{
        //     this.showDetector = false
        //     this.showIconFace = false
        //     console.log("hasil2", response);
        //   }
        // })
      }
    },
    // CAMERA STREAM END
    async takePhoto() {
      // if (!this.isPhotoTaken) {
      //   this.isShotPhoto = true;

      //   const FLASH_TIMEOUT = 50;

      //   setTimeout(() => {
      //     this.isShotPhoto = false;
      //   }, FLASH_TIMEOUT);
      // }

      // this.isPhotoTaken = !this.isPhotoTaken;

      let formData = new FormData();
      let canvas = document.getElementById("photoTaken");

      let ctx = canvas.getContext("2d");
      let vid = this.$refs.video;

      canvas.width = vid.width;
      canvas.height = vid.height;
      ctx.drawImage(vid, 0, 0, 250, 150);

      let file = await fetch(canvas.toDataURL("image/jpeg")).then(function(
        response
      ) {
        return response.blob();
      });

      formData.append("file", file, `image-1.jpg`);
      formData.append("source", "upload");
      formData.append("title", `image-1}`);

      // console.log('result', formData);
      this.axios
      .post("/storage/v1/media/member/upload", formData,{
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
        .then((res) => {
          // console.log('id',res)
            this.images_capture.push(res.data.data.id)
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    async takePhoto2() {
      if (!this.isPhotoTaken2) {
        this.isShotPhoto2 = false;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto2 = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken2 = !this.isPhotoTaken2;

      let formData = new FormData();
      let canvas = document.getElementById("photoTaken2");

      let ctx = canvas.getContext("2d");
      let vid = this.$refs.video;

      canvas.width = vid.width;
      canvas.height = vid.height;
      ctx.drawImage(vid, 0, 0, 250, 150);

      let file = await fetch(canvas.toDataURL("image/jpeg")).then(function(
        response
      ) {
        return response.blob();
      });

      formData.append("file", file, "Image_2.jpg");
      // formData.append("source", "upload");
      // formData.append("title", "Image_2");

      formData.append("source", "upload");
      formData.append("title", `image-2`);

      this.axios
      .post("/storage/v1/media/member/upload", formData,{
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
        .then((res) => {

            this.images_capture.push(res.data.id)
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getExplan(){
      this.$set(this.act, "current", 1);
        this.axios
              .get(`/users/v1/myprogram/answer_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                  this.$set(this.act, "list_question_explan", res.data);
                  this.setQuestionExplan();                  
              });
    },
    getExplanExam(){
      this.activity_loading=true;
      this.$set(this.act, "current", 1);
      this.axios
      .get(`/users/v1/myprogram/answer_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
      .then((res) => res.data)
      .then(async (res) => {
          this.$set(this.act, "list_question_explan", res.data);
          this.setQuestionExplanExam();

      })
      .catch((error) => {
      let message = error.response ? error.response.data.message : error.message;
      this.notification = {
        state: true,
        message,
      };
      });
    },
    setQuestionExplan() {
      this.result = false;
      let question_count = 0;
      this.act.list_question_explan.forEach((question, index ) => {
          this.axios(`/bank/v1/question/public/detail/${question.question_id}`,{
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
          })
            .then((res) => res.data)
            .then((res) => {
              this.$set(question, 'test', res.data);
              if (++question_count == this.act.list_question_explan.length) {
                this.isLoading = false;
              }
            });
      });
    },
    setQuestionExplanExam() {
      this.activity_loading=true;      
      this.result = false;
        let question_count = 0;
        const question_group = this.act.list_question_explan.map((val) => {
          const a = val.question_id;
          return a ;
        });
        const data = {
          id:question_group
        }
        this.axios.post(`/bank/v1/question/public/detail_multiple`,data,{
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
        })
          .then((res) => res.data)
          .then((res) => {
            this.activity_loading=false;
            res.data.forEach((question, index) => {
              this.$set(this.act.list_question_explan[index], 'test', question);
              if (++question_count == this.act.list_question_explan.length) {
                this.isLoading = false;
              }
            })

          });      
    },
    setQuestion() {
      this.result = false;
      const total_question = this.act.list_question.reduce((cur, val) => {
        return cur + val.list_question.length;
      }, 0);
      let question_count = 0;
      this.act.list_question.forEach(({ list_question }) => {
        list_question.forEach((question, index) => {
          // this.axios(`/users/v1/myprogram/question/detail/${question.question_id}`,{
          this.axios(`/bank/v1/question/public/detail/${question.question_id}`,{
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
          })
            .then((res) => res.data)
            .then((res) => {
              this.$set(list_question, index, res.data);
              if (++question_count == total_question) {
                this.isLoading = false;
              }
            });
        });
      });
    },
    setQuestionExam() {
      this.activity_loading=true;            
      this.result = false;
      let total_question = 0;
      this.act.list_question_exam.forEach((value) => {
        total_question += value.groups.reduce((cur, val) => {
          return cur + val.list_question.length;
        }, 0);
      })

      // this.$set(this.act, "total_question", total_question);

      let question_count = 0;
      this.act.list_question_exam.forEach(({ groups }) => {
        // console.log('groups', groups);
        if (groups.length > 0) {
          // groups.total_question = total_question
          groups.forEach(( list_question, index) => {
            // list_question.list_question.forEach((question, index) => {
              // console.log('list', list_question.list_question);
              const question_group = list_question.list_question.map((val) => {
                const a = val.question_id;
                return a ;
              });
              const data = {
                id:question_group
              }
              // this.axios(`/bank/v1/question/public/detail/${question.question_id}`,{
              this.axios.post(`/bank/v1/question/public/detail_multiple`,data,{
                headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
              })
                .then((res) => res.data)
                .then((res) => {
                  this.activity_loading=false;                        
                  res.data.forEach((question, index) => {
                    // console.log('res', question);
                    this.$set(list_question.list_question, index, question);
                    // console.log('list_question', list_question);
                    if (++question_count == total_question) {
                      this.isLoading = false;
                    }
                  })

                });
            // })
          });

        }
      });
    },
    // timer_start(timers) {
    //   console.log('isa')
    //   this.timer_stop()
    //   this.totalSeconds = 0
    //   this.totalSeconds = timers*60;
    //   this.takePhoto();
    //   this.timer = setInterval(() => {
    //     this.totalSeconds -= 1;
    //     if (this.totalSeconds === 0) {
    //       this.submitPostFalse();
    //       this.totalSeconds = 0;
    //       clearInterval(this.timer);
    //     }
    //   }, 1000);
    // },
    timer_start_quiz(timers) {
      this.timer_stop()
      this.totalSeconds = 0
      this.totalSeconds = timers*60;
      this.takePhoto();
      this.timer = setInterval(() => {
        this.totalSeconds -= 1;
        if (this.totalSeconds === 0) {
          this.submitQuizFalse();
          this.totalSeconds = 0;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    timer_stop() {
      clearInterval(this.timer);
    },
    onDrop: function(e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    onChange(e) {
      var files = e.target.files[0];
      this.image = URL.createObjectURL(files);
      this.file = e.target.files[0];
      // this.createFile(files[0]);
      // this.uploadCover(files[0]);
    },
    createFile(file) {
      // if (!file.type.match('image.*')) {
      //   alert('Select an image');
      //   return;
      // }
      // var img = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = function(e) {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeFile() {
      this.image = "";
    },
    submitUpload() {
      this.loadUpload = true;
      // setTimeout(() => {
        let formData = new FormData();

        formData.append("member_redeem_id", this.detailClass.id);
        formData.append("file", this.file);
        formData.append("topic_id", this.topics[this.topics_active].id);
        formData.append("activity_id", this.act.id);
        // console.log('upload',formData);
        this.axios
          // .post("/storage/v1/media/member/upload", formData,{timeout: 100000}, {
          .post("/users/v1/myprogram/upload_activity", formData,{
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => res.data)
          .then((res) => {
            this.loadUpload = false;
            this.result = res.data;
            this.successUpload = true;
            this.viewUpload = false;
            this.getUnlock(this.topics[this.topics_active].id, this.act.id);
            this.image = "";
          })
          .catch((error) => {
            this.loadUpload = false;            
            let message = error.response ? error.response.data.message : error.message;
            this.notification = {
              state: true,
              message,
            };
            console.error(error);
          })
          .finally(() => {
            this.activity_loading = false;
          });
      // }, 100000);
    },
    submitReview(setting) {
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.id;
          const answer = v.options[v.answer] ? [v.options[v.answer].text] : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.topics[this.topics_active].id,
        activity_id: this.act.id,
        question_group,
      };
      // console.log("minimum", minimum);
      // console.log("act", act);
      // console.log("topik", topic);

      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.getActFalse(this.topics[this.topics_active].id, this.act.id);
          let score = res.data.total_activity_score;          
          let minimum = setting.set_minimum_value.minimum_value;

          if(setting.set_minimum_value.is_set===true){            
            if (score >= minimum ) {
              // console.log('score',score)
              this.getUnlock(this.topics[this.topics_active].id, this.act.id);
            }
          } else{
            this.getUnlock(this.topics[this.topics_active].id, this.act.id);
          }                    

          // this.review = true;
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban terkirim",
          };
          this.showQues = false;
          this.resultNilai = true;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitReviewFalse(setting) {
      // console.log('act',act);      
      // console.log('topik',this.topics[this.topics_active].id);      
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.id;
          const answer = v.options[v.answer] ? [v.options[v.answer].text] : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.topics[this.topics_active].id,
        activity_id: this.act.id,
        question_group,
      };

      // console.log("minimum", minimum);
      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.getActFalse(this.topics[this.topics_active].id, this.act.id);
          let score = res.data.total_activity_score;          
          let minimum = setting.set_minimum_value.minimum_value;

          if(setting.set_minimum_value.is_set===true){            
            if (score >= minimum ) {
              // console.log('score',score)
              this.getUnlock(this.topics[this.topics_active].id,this.act.id);
            }
          } else{
            this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          }
          // this.getUnlock(this.topics[this.topics_active].id, this.act.id);

          // let score = res.data.total_activity_score;
          // console.log('score',score)

          // if (score >= minimum ) {
          //   console.log('score',score)
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }

          // this.review = true;
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesFalse = false;
          this.resultNilaiFalse = true;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitPost(setting) {
      this.timer_stop();            
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.id;
          const answer = v.options[v.answer] ? [v.options[v.answer].text] : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.topics[this.topics_active].id,
        activity_id: this.act.id,
        question_group,
        images : this.images_capture,        
      };
      // console.log("minimum", minimum);
      // console.log("act", act);
      // console.log("topik", topic);

      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.getActFalse(this.topics[this.topics_active].id, this.act.id);
          let score = res.data.total_activity_score;          
          let minimum = setting.set_minimum_value.minimum_value;

          if(setting.set_minimum_value.is_set===true){            
            // if (score >= minimum ) {
              this.getUnlock(this.topics[this.topics_active].id, this.act.id);
            // }
          } else{
            this.getUnlock(this.topics[this.topics_active].id, this.act.id);
          }                    

          // this.review = true;
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban terkirim",
          };
          this.showQuesPost = false;
          this.resultNilaiPost = true;
          this.showStartPost=false;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitPostFalse(setting) {
      this.timer_stop();
      // console.log('act',act);      
      // console.log('topik',this.topics[this.topics_active].id);      
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.id;
          const answer = v.options[v.answer] ? [v.options[v.answer].text] : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.topics[this.topics_active].id,
        activity_id: this.act.id,
        question_group,
        images : this.images_capture,
      };

      // console.log("minimum", minimum);
      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.getActFalse(this.topics[this.topics_active].id, this.act.id);
          let score = res.data.total_activity_score;          
          let minimum = setting.set_minimum_value.minimum_value;

          if(setting.set_minimum_value.is_set===true){            
            // if (score >= minimum ) {
              this.getUnlock(this.topics[this.topics_active].id,this.act.id);
            // }
          } else{
            this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          }
          // this.getUnlock(this.topics[this.topics_active].id, this.act.id);

          // let score = res.data.total_activity_score;
          // console.log('score',score)

          // if (score >= minimum ) {
          //   console.log('score',score)
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }

          // this.review = true;
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesPostFalse = false;
          this.showStartPostFalse=false;
          this.resultNilaiPostFalse = true;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitExam(setting) {
      // console.log('act : ',setting);  
      // console.log('list_question_exam : ', this.act.list_question_exam); 
      this.activity_loading = true;
      let data = {}  
        const question_section = this.act.list_question_exam.map((value) => {
          const question_group = value.groups.map((val) => {
            const questions = val.list_question.map((v) => {
              const question_id = v.id;
              const answer = v.options[v.answer] ? [v.options[v.answer].text] : [];
              return {
                question_id,
                answer,
              };
            });
            return {
              group_id: val.group_id,
              questions,
            };
          })

          return {
            section_id: value.id,
            question_group
          }
        });

        data = {
          id: this.detailClass.id,
          topic_id: this.id_topic,
          activity_id: this.id_act,
          sections: question_section,
          question_group: []
        };
        this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.resultNilaiExam=true;
          this.showQuesExam=false;
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;          
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){            
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   // }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesExamFalse = false;
          this.resultNilaiExamFalse = true;
          this.timer_stop();
          this.totalSeconds=0;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitExamFalse(setting) {
      // console.log('act : ',setting);  
      // console.log('list_question_exam : ', this.act.list_question_exam); 
      this.activity_loading = true;
      let data = {}  
        const question_section = this.act.list_question_exam.map((value) => {
          const question_group = value.groups.map((val) => {
            const questions = val.list_question.map((v) => {
              const question_id = v.id;
              const answer = v.options[v.answer] ? [v.options[v.answer].text] : [];
              return {
                question_id,
                answer,
              };
            });
            return {
              group_id: val.group_id,
              questions,
            };
          })

          return {
            section_id: value.id,
            question_group
          }
        });

        data = {
          id: this.detailClass.id,
          topic_id: this.id_topic,
          activity_id: this.id_act,
          sections: question_section,
          question_group: []
        };
        this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.resultNilaiExamFalse=true;
          this.showQuesExamFalse=false;
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;          
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){            
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   // }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesExamFalse = false;
          this.resultNilaiExamFalse = true;
          this.timer_stop();
          this.totalSeconds=0;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitQuiz(setting) {
      this.timer_stop();            

      // if (this.images_capture.length < 2) {
      //    this.takePhoto();
      // }
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.id;
          const answer = v.options[v.answer] ? [v.options[v.answer].text] : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.topics[this.topics_active].id,
        activity_id: this.act.id,
        question_group,
        images : this.images_capture,        
      };      

      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.getActFalse(this.topics[this.topics_active].id, this.act.id);
          let score = res.data.total_activity_score;

          let minimum = setting.set_minimum_value.minimum_value;
          
          if(setting.set_minimum_value.is_set===true){            
            if (score >= minimum ) {
              // console.log('score',score)
              this.getUnlock(this.topics[this.topics_active].id,this.act.id);
            }
          } else{
            this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          }
          
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban terkirim",
          };
          this.showQuesQuiz = false;
          this.resultNilaiQuiz = true;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitQuizFalse(setting) {
      this.timer_stop();            

      this.activity_loading = true;
      // if (this.images_capture.length < 2) {
      //    this.takePhoto();
      // }
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.id;
          const answer = v.options[v.answer] ? [v.options[v.answer].text] : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.topics[this.topics_active].id,
        activity_id: this.act.id,
        question_group,
        images : this.images_capture,
      };

      // console.log("param", data);
      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.getActFalse(this.topics[this.topics_active].id, this.act.id);
          let score = res.data.total_activity_score;
          // console.log('score',score)
          let minimum = setting.set_minimum_value.minimum_value;
          
          if(setting.set_minimum_value.is_set===true){            
            if (score >= minimum ) {
              // console.log('score',score)
              this.getUnlock(this.topics[this.topics_active].id,this.act.id);
            }
          } else{
            this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          }


          // this.review = true;
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesQuizFalse = false;
          this.resultNilaiQuizFalse = true;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    getChange(topic, act) {
      const data = {
        // type:false,
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.resultNilai = false;
          this.showQues = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getChangeFalse(topic, act) {
      const data = {
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.resultNilaiFalse = false;
          this.showQuesFalse = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getChangePost(topic, act, setting) {
      const data = {
        // type:false,
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          if (setting.is_show_timer === true) {            
            this.resultNilaiPost = false;
            this.showQuesPost = false;
            this.showStartPost = true;
          }else{
            this.resultNilaiPost = false;
            this.showQuesPost = true;
            this.showStartPost = false;
          }
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getChangePostFalse(topic, act, setting) {
      const data = {
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          if (setting.is_show_timer === true) {
            this.resultNilaiPostFalse = false;
            this.showQuesPostFalse = false;
            this.showStartPostFalse=true;
          }else{
            this.resultNilaiPostFalse = false;
            this.showQuesPostFalse = true;
            this.showStartPostFalse=false;
          }
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getChangeExam(topic, act,type,setting) {
      this.activity_loading=true;
      const data = {
        // type:false,
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      // console.log(data);
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading=false;
          this.resultNilaiExam = false;
          this.showQuesExam = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type,setting);
          }
        })
        .catch((error) => {
          console.error(error.response);
          let errorCode = error.response.status
          let errorData = error.response.data

          if (errorCode == 400) {
            this.notification = {
              state: true,
              message: errorData.message,
            };
          }
        });
    },
    getChangeExamFalse(topic, act,type,setting) {
      this.activity_loading=true;
      const data = {
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading=false;
          this.resultNilaiExamFalse = false;
          this.showQuesExamFalse = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type,setting);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getChangeQuiz(topic, act, setting) {
      const data = {
        // type:false,
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          if (setting.is_show_timer === true) {            
            this.resultNilaiQuiz = false;
            this.showQuesQuiz = false;
            this.showStartQuiz = true;
          }else{
            this.resultNilaiQuiz = false;
            this.showQuesQuiz = true;
            this.showStartQuiz = false;
          }
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getChangeQuizFalse(topic, act, setting) {
      const data = {
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          if (setting.is_show_timer === true) {
            this.resultNilaiQuizFalse = false;
            this.showQuesQuizFalse = false;
            this.showStartQuizFalse=true;
          }else{
            this.resultNilaiQuizFalse = false;
            this.showQuesQuizFalse = true;
            this.showStartQuizFalse=false;
          }
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    changeStatus() {
      this.getUnlock(this.topics[this.topics_active].id, this.act.id);
    },
    getUnlock(topic, act) {
      const data = {
        program_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/unlock`, data)
        .then(async () => {
          this.getDetailClass();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getUnlockUpload() {
      this.load_hit_unlock=true;
      const data = {
        program_id: this.$route.params.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
      };
      this.axios
        .post(`/users/v1/member/activity/unlock`, data)
        .then(async (res) => {
          this.load_hit_unlock=false;
          this.notification = {
            state: true,
            message: res.data.message,
          };
          this.getDetailClass();
          this.getActFalse(this.id_topic, this.act.id);          
          this.hit_unlock=false;
        })
        .catch((error) => {
          console.error(error);
          this.notification = {
            state: true,
            message: error.response.data.message,
          };
        });
    },
    repeat() {
      this.resultNilai = false;
      this.showQues = true;
      this.showExplanation=false;
      this.getActFalse(this.topics[this.topics_active].id, this.act.id);      
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    repeatFalse() {
      this.resultNilaiFalse = false;
      this.showQuesFalse = true;
      this.showExplanationFalse=false;
      this.getActFalse(this.topics[this.topics_active].id, this.act.id);
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    repeatPost(act) {
      if (act.is_show_timer === true) {
        this.resultNilaiPost = false;
        this.showQuesPost = false;
        this.showExplanationPost=false;
        this.showStartPost=true;
      }else{
        this.resultNilaiPost = false;
        this.showQuesPost = true;
        this.showExplanationPost=false;
        this.showStartPost=false;
      }
      this.getActFalse(this.topics[this.topics_active].id, this.act.id);      
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    repeatFalsePost(act) {
      if (act.is_show_timer === true) {
        this.resultNilaiPostFalse = false;
        this.showQuesPostFalse = false;
        this.showExplanationPostFalse=false;
        this.showStartPostFalse=true;
      }else{
        this.resultNilaiPostFalse = false;
        this.showQuesPostFalse = true;
        this.showExplanationPostFalse=false;
        this.showStartPostFalse=false;
      }
      this.getActFalse(this.topics[this.topics_active].id, this.act.id);
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    question_status(index) {
      if (!this.show_direction) {
        if (!index || !this.act.current) {
          return "bg-accent-default";
        }
        if (index == this.act.current) {
          return "bg-accent-orange";
        }

        if (this.questionsExam[index - 1].answer != undefined) {
          return "bg-accent-green";
        }
      }
      return "bg-accent-default";
    },
    goNumber(number) {
      this.act.current = number;
    },
    timer_start(type,setting) {
      this.timer_stop()
      this.totalSeconds = 0
      this.totalSeconds=setting.timer*60;
      this.timer = setInterval(() => {
        this.totalSeconds-=1;
        if (this.totalSeconds === 0) {
          if (type === 'pre_test') {
            this.submitReview(setting)
          }else if (type === 'post_test'){
            this.submitPostFalse(setting)
          }else if (type === 'exam'){
            this.submitExamFalse(setting)
          }else{
            this.submitQuizFalse(setting)
          }
          this.totalSeconds = 0;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    repeatQuiz(act) {
      console.log(act.is_show_timer)
      if (act.is_show_timer === true) {
        this.resultNilaiQuiz = false;
        this.showQuesQuiz = false;
        this.showExplanationQuiz=false;
        this.showStartQuiz=true;
      }else{
        this.resultNilaiQuiz = false;
        this.showQuesQuiz = true;
        this.showExplanationQuiz=false;
        this.showStartQuiz=false;
      }
      this.getActFalse(this.topics[this.topics_active].id, this.act.id);      
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    repeatQuizFalse(act) {
      console.log(act.is_show_timer)      
      if (act.is_show_timer === true) {
        this.resultNilaiQuizFalse = false;
        this.showQuesQuizFalse = false;
        this.showExplanationQuizFalse=false;
        this.showStartQuizFalse=true;
      }else{
        this.resultNilaiQuizFalse = false;
        this.showQuesQuizFalse = true;
        this.showExplanationQuizFalse=false;
        this.showStartQuizFalse=false;
      }
      this.getActFalse(this.topics[this.topics_active].id, this.act.id);
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    redirect(){
      this.getChange(this.topics[this.topics_active].id, this.act.id)
    },
    redirectFalse(){
      this.getChangeFalse(this.topics[this.topics_active].id, this.act.id)
    },
    redirectPost(act){
      this.getChangePost(this.topics[this.topics_active].id, this.act.id, act)
    },
    redirectPostFalse(act){
      this.getChangePostFalse(this.topics[this.topics_active].id, this.act.id, act)
    },
    redirectExam(type,setting){
      this.activity_loading=true
      this.getChangeExam(this.id_topic, this.act.id,type,setting)
      // this.repeat();      
    },
    redirectExamFalse(type,setting){
      this.activity_loading=true
      this.getChangeExamFalse(this.id_topic, this.act.id,type,setting)
    },
    redirectQuiz(act){
      this.getChangeQuiz(this.topics[this.topics_active].id, this.act.id, act)
    },
    redirectQuizFalse(act){
      this.getChangeQuizFalse(this.topics[this.topics_active].id, this.act.id, act)
    },
    currentDateTime(a) {
      return moment(a).format("L");
    },
    day(a) {
      // moment.lang("de").format('LLL');
      if(a ==='sunday'){
        return 'Minggu'
      }else if(a==='monday'){return 'Senin'}else if(a==='tuesday'){return 'Selasa'}else if(a==='wednesday'){return 'Rabu'}else if(a==='thursday'){return 'Kamis'}
      else{
        return 'Jumat'
      }
      // return moment(a).lang("id").format("D");
    },
    // dateTime(a) {
    //   return moment().minutes(a).format("H:mm:ss");
    // },
    dateTime(value) {
      var minut=value*60;
      var hours = `${Math.floor(minut / 3600)}`.padStart(2, 0);
      var minutes = `${Math.floor(minut / 60) % 60}`.padStart(2, 0);
      var seconds = `${minut % 60}`.padStart(2, 0);
      return `${hours}:${minutes}:${seconds}`;
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .output("blob")
        .then((blob) => {
          let formData = new FormData();
          let certif = blob;

          formData.append("file", certif, "Sertifikat.pdf");
          formData.append("source", "upload");
          formData.append("title", "Sertifikat");

          // this.$store
          //   .dispatch("media/create", formData)
          //   .then((res) => {
          //     if (res.status == 200) {
          //       this.id_certif = res.data.id;
          //       this.updateCertif(this.id_certif);
          //       console.log("result", this.id_certif);
          //     }
          //   })
          //   .catch((error) => {
          //     console.log("error", error);
          //   });
        });
    },
    hasGenerated($event) {
      console.log($event);
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    ctfPeserta(type) {
      this.typeCertif = type;
      this.$refs.certifPeserta.generatePdf();
    },
    ctfKompetensi() {
      this.$refs.certifKom.generatePdf();
    },
    send() {
      this.subSend = true;
      const data = {
        license: this.detailClass.license,
        program_id: this.program.id,
        review: this.ulasan,
        star: this.rating,
      };
      this.axios
        .post(`/program_review/v1/create`, data, {
          headers: { "Content-Type": "application/json", },
        })
        .then(async () => {
          this.subSend = false;
          this.getDetailClass();
          // this.getReviewClass();
          this.notification = {
            state: true,
            message: "Review Terkirim",
          };
        })
        .catch((error) => {
          this.subSend = false;
          this.rating = null;
          this.ulasan = "";
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        });
    },
  },
  watch: {
    review(val) {
      if (val) {
        this.answer = [1, 3];
      } else {
        this.answer = null;
      }
    },
  },
};
</script>

<style>
#player .ytp-chrome-top-buttons {
  display: none !important;
}
#player .ytp-pause-overlay-controls-hidden .ytp-pause-overlay {
  display: none !important;
}
iframe {
  width: 100%;
  height: 100%;
}
/*#vid{
  pointer-events: none;
}*/
.custom-panels {
  border-top: thin solid;
  border-bottom: thin solid;
  border-color: rgba(0, 0, 0, 0.12);
}
.custom-panels .v-expansion-panel:before {
  box-shadow: none;
}
.custom-panels .v-expansion-panel--active + .v-expansion-panel,
.v-expansion-panel--active:not(:first-child) {
  margin-top: unset !important;
}
.custom-panels .v-expansion-panel-content__wrap {
  padding: unset !important;
}
.custom-panels .v-expansion-panel--active + .v-expansion-panel:after,
.custom-panels .v-expansion-panel--active:not(:first-child):after {
  opacity: unset;
}
.options .v-list-item {
  border: thin solid #d3dce6;
}
.options .v-list-item-group .v-list-item--active {
  border-color: currentColor;
}
.btn-img {
  background-color: #f05326;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 12px 16px;
  position: relative;
  border-radius: 4px;
}
.drop input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.drop {
  /*background-color: #F4F6F9;*/
  border: 1px dashed #ccc;
  /*background-color: #F4F6F9;*/
  border-radius: 2px;
  /*height: 250px;*/
  height: 60vh;
  width: 100%;
  vertical-align: middle;
}
.img-cover {
  width: 100%;
  object-fit: contain;
  height: 250px;
}
.btn-float {
  position: absolute;
  top: 10%;
  right: 5%;
}
.bg-label {
  background: rgb(245 245 245);
  padding: 8px;
  border-radius: 100%;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}
.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}
#tagImg img{
  max-width: 100%;
  height: auto;
}
.test-video {
  background-color: #000;
  border-radius: 5px;
  position: fixed;
  right: 1%;
  bottom: 3%;
  z-index:199;
}
#photoTaken {
  display: none;
}
#photoTaken2 {
  display: none;
}
</style>
