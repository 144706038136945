import { getCompanyDetail } from "../services/company";

const state = () => ({
  data: {},
});

const mutations = {
  setData(state, data) {
    state.data = data;
  },
};

const actions = {
  async getData({ commit }) {
    const { data: res } = await getCompanyDetail();
    commit("setData", res.data);
  },
};

const getters = {};

export default { state, mutations, actions, getters, namespaced: true };
