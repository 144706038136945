import Vue from 'vue'
import VueRouter from 'vue-router'
import Views from '../views'
import store from '../store'


Vue.use(VueRouter)

let routes = {
  auth: [
    // { path: '/beranda', name: 'beranda', component: Views.User.Home.HomeUser },
    { path: '/account', name: 'account', component: Views.User.Account.AccountSetting },
    // { path: '/prakerja', name: 'prakerja', component: Views.User.Home.Prakerja },
    { path: '/detail-kelas-user/:id', name: 'detail-kelas-user', component: Views.User.Home.DetailUser },
    { path: '/kelas', name: 'my-class', component: Views.User.MyClass.MyClass },
    { path: '/kelas/:id', name: 'detail-class', component: Views.User.MyClass.DetailClass },
    { path: '/sertifikat', name: 'sertifikat', component: Views.User.Certif.CertifList },

  ],
  guest: [
    // { path: '/', name: 'home', component: Views.Public.PublicBeranda },
    // { path: '/detail-kelas/:id', name: 'detail-kelas', component: Views.Public.PublicDetail },
    { path: '/', name: 'login', component: Views.Auth.Login },
    { path: '/auth/success-verify-email/:code', name: 'success-verify-email', component: Views.Auth.SuccessVerif },
    { path: '/auth/registration', name: 'registration', component: Views.Auth.Registration },
    { path: '/auth/password-send-phone', name: 'password-send-phone', component: Views.Auth.PasswordSendEmail },
    { path: '/auth/success-send-email', name: 'success-send-email', component: Views.Auth.SuccessSendEmail },
    { path: '/auth/password-reset/:id', name: 'password-reset', component: Views.Auth.PasswordReset },
    { path: '/auth/success-reset-password', name: 'success-reset-password', component: Views.Auth.SuccessResetPassword },
    { path: '/kelas-prakerja', name: 'prakerja', component: Views.Public.PublicPrakerja },    
    
  ],
  default: [
    { path: '*', name: '404 Not Found', component: Views.Errors.NotFound },
    { path: '/certificate-participant/:id', name: 'certificate-participant', component: Views.User.Certif.CertifPart },
    { path: '/certificate-graduation/:id', name: 'certificate-graduation', component: Views.User.Certif.CertifGrad },
    // { path: '/detail-kelas/:id', name: 'detail-kelas', component: Views.Public.PublicDetail },
  ]
}

routes.auth.forEach(route => { route.meta = { ...route.meta, auth: true } });
routes.guest.forEach(route => { route.meta = { ...route.meta, guest: true } });
routes = [
  ...routes.auth,
  ...routes.guest,
  ...routes.default,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // if (to.path == '/' && store.getters.isLoggedIn) {
  //   return next('/beranda')
  // }
  // if (to.meta.auth && !store.getters.isLoggedIn) {
  //   sessionStorage.setItem('ytc_prakerja_redirect', location.pathname)
  //   return next('/')
  // }
  // if (to.meta.guest && store.getters.isLoggedIn) {
  //   return next('/beranda')
  // }
  // return next()
   if (store.getters.isLoggedIn) {
    if (store.getters.user.is_updated_profile === false && to.path != '/account') {
      return next('/account')
    }
    if (to.path == '/' ||  to.meta.guest) {
      return next('/kelas')
    }
  } 
  if (to.meta.auth && !store.getters.isLoggedIn) {
    localStorage.setItem('ytc_prakerja_redirect', location.pathname)
    return next('/')
  }
  
  return next()
})

export default router
